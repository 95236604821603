import { Video, VideoProps } from '@maverick/cms';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type VideoRenderingProps = ComponentProps & {
	fields: {
		Variant: { value: string };
		Title: { value: string };
		Text?: { value: string };
		Link: { value: string};
		BackgroundImage?: { value: { src: string } };
	};
};

const VideoRendering = (props: VideoRenderingProps): JSX.Element => {
	const videoProps: VideoProps = {
		variant: props.fields?.Variant?.value === 'Dark' ? 'dark' : 'light',
		title: props?.fields?.Title?.value,
		text: props?.fields?.Text?.value,
		bgImage: props?.fields?.BackgroundImage?.value?.src,
		youtubeVideoCode: props?.fields?.Link?.value,
	};

	return <Video {...videoProps} />;
};

export default withDatasourceCheck()<VideoRenderingProps>(VideoRendering);
