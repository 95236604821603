import {
	RichText,
	Field,
	withDatasourceCheck,
	ImageField,
	Image as SitecoreImage,
} from '@sitecore-jss/sitecore-jss-nextjs';
import {
	BodyBanner,
	BodyBannerProps,
	BodyBannerStyled,
	BodyBannerVariant,
	BodyBannerVariantProps,
} from '@maverick/cms';
import { ComponentProps } from 'lib/component-props';

type BodyBannerRenderingProps = ComponentProps & {
	rendering: {
		fields: {
			Variant: { value: string };
			Overtitle: Field<string>;
			Title: Field<string>;
			Subtitle: Field<string>;
			Text: Field<string>;
			'Background Image': { value: { src: string } };
			'First Image': ImageField;
			'Second Image': ImageField;
			'First Button': { value: { href: string; text: string; target: string } };
			'Second Button': { value: { href: string; text: string; target: string } };
			'Button Style': Field<string>;
			BodyBannerAnchors: Array<BodyBannerAnchor>;
		};
	};
};

interface BodyBannerAnchor {
	displayName: string;
	fields: { Anchor: { value: { href: string } } };
}

const BodyBannerRendering = (props: BodyBannerRenderingProps): JSX.Element => {
	const {
		Title,
		Overtitle,
		Subtitle,
		Text,
		'First Image': Image1,
		'Second Image': Image2,
		'First Button': Button,
		'Second Button': Button2,
		'Background Image': BackgroundImage,
		BodyBannerAnchors,
		Variant,
	} = props?.rendering?.fields;

	if (Variant.value === 'Secondary') {
		const bodyBannerVariantProps: BodyBannerVariantProps = {
			title: <RichText field={Title} />,
			image1: <SitecoreImage field={Image1} />,
			image2: <SitecoreImage field={Image2} />,
			button: { label: Button?.value?.text, href: Button?.value?.href, target: Button?.value?.target },
			bgImage: BackgroundImage?.value?.src,
		};
		return <BodyBannerVariant {...bodyBannerVariantProps} />;
	} else {
		const bodyBannerProps: BodyBannerProps = {
			mainContent: (
				<>
					{Overtitle?.value && (
						<BodyBannerStyled.Text>
							<RichText field={Overtitle}></RichText>
						</BodyBannerStyled.Text>
					)}
					{Title?.value && (
						<BodyBannerStyled.Title>
							<RichText field={Title} />
						</BodyBannerStyled.Title>
					)}
					{Subtitle?.value && (
						<BodyBannerStyled.Subtitle>
							<RichText field={Subtitle} />
						</BodyBannerStyled.Subtitle>
					)}
					{Text?.value && (
						<BodyBannerStyled.Text>
							<RichText field={Text} />
						</BodyBannerStyled.Text>
					)}
				</>
			),
			image1: Image1?.value?.src && (
				<BodyBannerStyled.ImageBigContainer>
					<SitecoreImage field={Image1} />
				</BodyBannerStyled.ImageBigContainer>
			),
			image2: Image2?.value?.src && (
				<BodyBannerStyled.BadgeImageContainer>
					<SitecoreImage field={Image2} />
				</BodyBannerStyled.BadgeImageContainer>
			),
			button: (Button?.value?.text || Button2.value?.text) && (
				<BodyBannerStyled.ButtonContainer>
					{Button?.value?.text && (
						<BodyBannerStyled.Button href={Button?.value?.href} target={Button?.value?.target}>
							{Button?.value?.text}
						</BodyBannerStyled.Button>
					)}
					{Button2?.value?.text && (
						<BodyBannerStyled.Button href={Button2?.value?.href} target={Button2?.value?.target}>
							{Button2?.value?.text}
						</BodyBannerStyled.Button>
					)}
				</BodyBannerStyled.ButtonContainer>
			),
			bgImage: BackgroundImage?.value?.src,
			navList: BodyBannerAnchors?.map((item: BodyBannerAnchor) => {
				return { label: item.displayName, href: item.fields?.Anchor?.value?.href };
			}),
		};
		return <BodyBanner {...bodyBannerProps} />;
	}
};

export default withDatasourceCheck()<BodyBannerRenderingProps>(BodyBannerRendering);
