import { Icon } from '@maverick/ui';
import { IconChevronDown } from '@maverick/icons/dist/IconChevronDown';
import { FC } from 'react';
import styled, { keyframes } from 'styled-components';

const movement = keyframes`
    0% {translate: 0 -10px;}
    50% {translate: 0 0;}
    100% {translate: 0 -10px;}
`;

const Container = styled.div`
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	color: ${({ theme }) => theme.colors.neutral.white};
	animation: ${movement} 2s infinite;
	cursor: pointer;
	transition: all ease 0.2s;

	&:hover {
		opacity: 0.8;
	}
`;
const Label = styled.div`
	font-family: 'jenna-sue';
	font-size: 2.4rem;

	@media (max-width: 768px) {
		font-size: 1.5rem;
	}
`;
const Chevron = styled.div``;

interface SteakStoryScrollCtaProps {
	label: string;
}
export const SteakStoryScrollCta: FC<SteakStoryScrollCtaProps> = ({ label }) => {
	return (
		<Container>
			<Label>{label}</Label>
			<Chevron>
				<Icon icon={IconChevronDown} />
			</Chevron>
		</Container>
	);
};
