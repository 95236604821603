import { DottedLine, DottedLineProps, DottedLineVariant, FilterImg, ImgType } from '@maverick/cms';
import { ComponentProps } from 'lib/component-props';

type DottedLineRenderingProps = ComponentProps & {
	params: {
		Border: string;
		Color: string;
		OnlyMobile: boolean;
		Variant: string;
	};
};

const DottedLineRendering = (props: DottedLineRenderingProps): JSX.Element => {
	const dottedLineProps: DottedLineProps = {
		variant: props?.params?.Variant === 'Secondary' ? DottedLineVariant.Secondary : DottedLineVariant.Primary,
		img: props?.params?.Border as ImgType,
		filter: props?.params?.Color as FilterImg,
		onlyMobile: props?.params?.OnlyMobile,
	};

	return <DottedLine {...dottedLineProps} />;
};

export default DottedLineRendering;
