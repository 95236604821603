import { GetStaticComponentProps } from '@sitecore-jss/sitecore-jss-nextjs';
import { BlogList } from '../../cms/BlogList';
import { Article, GetArticles } from '../../../services/BlogList.service';
import { ComponentProps } from 'lib/component-props';

export const getStaticProps: GetStaticComponentProps = async () => {
	return { items: await GetArticles() };
};

type BlogListRenderingProps = ComponentProps & { items: Array<Article> };

const BlogListRendering = (props: BlogListRenderingProps): JSX.Element => {
	return <BlogList items={props.items} />;
};

export default BlogListRendering;
