import { GoToWrapper } from './GoTo.wrapper';
import { FC } from 'react';

export const GoToApp: FC = () => {

	const deeplink = 'outback://home';
	const appStoreAndroid = 'https://play.google.com/store/apps/details?id=com.outback.tampa';
	const appStoreIos = 'https://apps.apple.com/us/app/outback/id1035361376';

	const goToApp = () => {
		window.location.replace(deeplink);

		setTimeout(() => {
			const isIOS = /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent);
			const storeUrl = isIOS ? appStoreIos : appStoreAndroid;
			window.location.replace(storeUrl);
		}, 5000);
	};
	return <GoToWrapper redirectMethod={goToApp} />;
};
