import { AnimatedHero, AnimatedHeroProps, AnimatedHeroStyled } from '@maverick/ui';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type AnimatedHeroRenderingProps = ComponentProps & {
	fields: {
		BackgroundImage: { value: { src: string } };
		BackgroundColor: { value: string };
		Title: { value: string };
		Text?: { value: string };
		Link?: { value: { href: string; text: string } };
		ImageLeft: { value: { src: string; alt?: string } };
		ImageRight: { value: { src: string; alt?: string } };
	};
};

const AnimatedHeroRendering = (props: AnimatedHeroRenderingProps): JSX.Element => {
	const getLink = () => {
		if (props?.fields?.Link?.value.text) {
			const link = {
				label: props.fields.Link.value.text,
				href: props.fields.Link.value.href,
			};
			return link;
		}
		return undefined;
	};

	const onClick = () => {
		if (getLink() && getLink()?.href) {
			window.open(getLink()?.href);
		}
	};

	const ButtonComponent = getLink() && (
		<AnimatedHeroStyled.ButtonContainer>
			<AnimatedHeroStyled.Button>
				<AnimatedHeroStyled.ButtonText
					data-testid='hero-button'
					onClick={() => {
						onClick?.();
					}}
				>
					{getLink()?.label}
				</AnimatedHeroStyled.ButtonText>
			</AnimatedHeroStyled.Button>
		</AnimatedHeroStyled.ButtonContainer>
	);

	const animatedHeroProps: AnimatedHeroProps = {
		bgImage: props?.fields?.BackgroundImage?.value?.src,
		bgColor: props?.fields?.BackgroundColor?.value,
		imageLeft: props?.fields?.ImageLeft?.value?.src,
		imageLeftAltText: props?.fields?.ImageLeft?.value?.alt,
		imageRight: props?.fields?.ImageRight?.value?.src,
		imageRightAltText: props?.fields?.ImageRight?.value?.alt,
		title: props?.fields?.Title?.value,
		Button: ButtonComponent || undefined,
		text: props?.fields?.Text?.value,
	};

	return <AnimatedHero {...animatedHeroProps} />;
};

export default withDatasourceCheck()<AnimatedHeroRenderingProps>(AnimatedHeroRendering);
