import { withDatasourceCheck, GetStaticComponentProps, ComponentRendering } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { CmsFooter, FooterMenuItemProps } from '@maverick/ui';
import { SocialMediaIcons } from '../../_shared/SocialMediaIcons/SocialMediaIcons';
import { MenuLink } from 'components/_shared/MenuLink';
import { NavigationRendering, NavigationRoutes } from 'src/services/NavigationRoutes';
import { OneTrustModal } from '@maverick/cms';
import { NavigationProps, NavigationVariant } from '@maverick/entity';

type FooterNavigationRenderingProps = ComponentProps & {
	fields: {
		Facebook: {
			value: string;
		};
		Instagram: {
			value: string;
		};
		Twitter: {
			value: string;
		};
	};
	routes: NavigationProps;
};

type RenderingProps = ComponentRendering & NavigationRendering;
export const getStaticProps: GetStaticComponentProps = async (rendering: RenderingProps, layoutData) => {
	const language = layoutData.sitecore.context.language!;
	return { routes: await NavigationRoutes.Routes({ ...rendering, language }) };
};

const FooterNavigationRendering = (props: FooterNavigationRenderingProps): JSX.Element => {
	const menuItems: FooterMenuItemProps[] | undefined = props.routes?.featuredItems?.map((item) => {
		return {
			variant: NavigationVariant.Featured,
			link: MenuLink(item.url, item.label, !item.newTab),
		};
	});
	props.routes?.unfeaturedItems?.forEach((item) => {
		menuItems?.push({
			variant: NavigationVariant.Unfeatured,
			link: MenuLink(item.url, item.label, !item.newTab),
		});
	});

	return (
		<CmsFooter
			footerMenuItems={menuItems}
			socialMediaIcons={SocialMediaIcons(
				props.fields.Instagram.value,
				props.fields.Facebook.value,
				props.fields.Twitter.value
			)}
			oneTrustModal={<OneTrustModal />}
		/>
	);
};

export default withDatasourceCheck()<FooterNavigationRenderingProps>(FooterNavigationRendering);
