import {
	GetStaticComponentProps,
	withDatasourceCheck,
	ComponentRendering,
	LayoutServiceData,
	Image as SitecoreImage,
	Field,
	LinkField,
	RichText,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import {
	Catalog,
	CatalogProps,
	CatalogItem,
	CatalogItemProps,
	CatalogItemVariant,
	CatalogItemSecondaryProps,
	CatalogItemSecondaryVariant,
	CatalogItemSecondary,
	CatalogItemSecondaryStyled,
	CatalogItemStyled,
	GridVariants,
} from '@maverick/cms';
import { IconChevronRight } from '@maverick/icons/dist/IconChevronRight';
import { Icon } from '@maverick/ui';
import { OutbackRedButton, OutbackTransparentButton } from '@maverick/themes';
import { ButtonType } from '@maverick/entity';
import {
	CatalogResponse,
	CatalogService,
	SitecoreCatalogRawChildren,
	SitecoreCatalogRawProps,
} from '../../../services/Catalog.service';

type CatalogRenderingProps = ComponentProps &
	SitecoreCatalogRawChildren & {
		fields: {
			Title: Field<string>;
			Button: LinkField;
			Variant: Field<string>;
			Columns: { value: GridVariants };
		};
		items: SitecoreCatalogRawProps[];
	};

const getButton = ({
	text,
	href,
	target,
	isVerticalView,
}: {
	text: string;
	href: string;
	target: string;
	isVerticalView?: boolean;
}) => {
	if (!text) return null;
	if (isVerticalView) {
		return (
			<OutbackRedButton href={href} target={target}>
				{text}
			</OutbackRedButton>
		);
	} else
		return (
			<OutbackTransparentButton href={href} target={target}>
				{text}
			</OutbackTransparentButton>
		);
};

export const getStaticProps: GetStaticComponentProps = async (
	rendering: ComponentRendering,
	layoutData: LayoutServiceData
) => {
	const result = await CatalogService.GetCatalogItens<CatalogResponse>(
		rendering?.dataSource ?? '',
		layoutData?.sitecore?.context?.language ?? 'en'
	);

	return { items: result.body.item.children.results };
};

const CatalogRendering = (props: CatalogRenderingProps): JSX.Element => {
	let reverse = 1;

	const variant = props?.items?.at(0)?.variant?.value;

	const verticalView = props?.items?.at(0)?.verticalView?.value;

	const catalogProps: CatalogProps = {
		title: props?.fields?.Title?.value ?? '',
		button: {
			href: props?.fields?.Button?.value?.href ?? '/',
			text: props?.fields?.Button?.value?.text ?? '',
			target: props?.fields?.Button?.value?.target ?? '',
		},
		variant: variant,
		verticalView: verticalView ?? false,
	};

	const getItensProps = (item?: SitecoreCatalogRawProps) => {
		const CatalogItemProps: CatalogItemProps = {
			title: item?.title ? <RichText field={item.title} /> : undefined,
			variant: reverse < 0 ? CatalogItemVariant.Primary : CatalogItemVariant.Secondary,
			subtitle: item?.subtitle ? <RichText field={item.subtitle} /> : undefined,
			description: item?.description ? <RichText field={item.description} /> : undefined,
			note: item?.note.value,
			image: item?.image ? <SitecoreImage field={item.image} /> : undefined,
			button: (item?.firstButton.text || item?.secondButton.text) && (
				<CatalogItemStyled.ButtonDiv>
					{getButton({
						href: item?.firstButton.url,
						text: item?.firstButton.text,
						target: item?.firstButton.target,
						isVerticalView: item?.verticalView?.value ?? false,
					})}
					{getButton({
						href: item?.secondButton.url,
						text: item?.secondButton.text,
						target: item?.secondButton.target,
						isVerticalView: item?.verticalView?.value ?? false,
					})}
				</CatalogItemStyled.ButtonDiv>
			),
			verticalView: item?.verticalView?.value ?? false,
			firstButtonLink: item?.firstButton.url,
			firstButtonTarget: item?.firstButton.target,
		};
		reverse = reverse * -1;

		return <CatalogItem {...CatalogItemProps} />;
	};

	const getCatalogItemSecondaryButton = (
		buttontype: string,
		{ text, url, target }: { text: string; url: string; target: string }
	) => {
		if (text === '') return null;

		if (buttontype === ButtonType.Contained) {
			return (
				<CatalogItemSecondaryStyled.CatalogSecondaryButtonContainer>
					<CatalogItemStyled.CatalogItemButton href={url} target={target}>
						{text}
					</CatalogItemStyled.CatalogItemButton>
				</CatalogItemSecondaryStyled.CatalogSecondaryButtonContainer>
			);
		}

		return (
			<CatalogItemSecondaryStyled.CatalogSecondaryButtonContainer>
				<CatalogItemSecondaryStyled.ButtonChevronContainer>
					<CatalogItemSecondaryStyled.CatalogSecondaryTextButton
						href={url}
						target={target}
						data-testid='button'
					>
						{text}
					</CatalogItemSecondaryStyled.CatalogSecondaryTextButton>
					<CatalogItemSecondaryStyled.CatalogSecondaryChevronButton>
						<Icon icon={IconChevronRight} customSize />
					</CatalogItemSecondaryStyled.CatalogSecondaryChevronButton>
				</CatalogItemSecondaryStyled.ButtonChevronContainer>
			</CatalogItemSecondaryStyled.CatalogSecondaryButtonContainer>
		);
	};

	const getItensSecondaryProps = (item?: SitecoreCatalogRawProps) => {
		const CatalogItemSecondaryProps: CatalogItemSecondaryProps = {
			title: item?.title ? <RichText field={item.title} /> : undefined,
			variant: reverse < 0 ? CatalogItemSecondaryVariant.Primary : CatalogItemSecondaryVariant.Secondary,
			subtitle: item?.subtitle ? <RichText field={item.subtitle} /> : undefined,
			description: item?.description ? <RichText field={item.description} /> : undefined,
			image: item?.image?.src ? <SitecoreImage field={item.image} /> : null,
			Button: getCatalogItemSecondaryButton(
				item?.buttonVariant?.value ?? ButtonType.Text,
				item?.firstButton ?? { url: '', text: '', target: '' }
			),
			firstButtonLink: item?.firstButton.url,
			firstButtonTarget: item?.firstButton.target,
		};
		reverse = reverse * -1;
		return <CatalogItemSecondary {...CatalogItemSecondaryProps} />;
	};

	const itemsProps =
		variant === 'Secondary'
			? props?.items?.map((item) => getItensSecondaryProps(item))
			: props?.items?.map((item) => getItensProps(item));

	return (
		<Catalog
			columns={props?.fields?.Columns?.value || GridVariants.THREE}
			{...catalogProps}
			children={itemsProps}
		/>
	);
};

export default withDatasourceCheck()<CatalogRenderingProps>(CatalogRendering);
