import {
	GetStaticComponentProps,
	RichText,
	withDatasourceCheck,
	Image as SitecoreImage,
	useComponentProps,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { Card, CardProps, CardVariant } from '@maverick/ui';
import { GetCardsList, SitecoreCardRawProps, SitecoreCardsRawChildren } from 'src/services/CardList.service';
import { Grid, GridVariants, GridProps, ButtonCardSecondary, CardSecondary, CardSecondaryProps } from '@maverick/cms';
import { ComponentProps } from 'lib/component-props';

type CardListRenderingProps = ComponentProps &
	SitecoreCardsRawChildren & {
		params: {
			Variant: string;
		};
	};

type SitecoreRawItems = {
	items: {
		results: SitecoreCardRawProps[];
	};
};

const getVariant = (variant: string) => {
	if (variant === 'One Column') return GridVariants.ONE;
	if (variant === 'Two Columns') return GridVariants.TWO;
	return GridVariants.THREE;
};

export const getStaticProps: GetStaticComponentProps = async (rendering) => {
	const result = await GetCardsList(rendering.dataSource!);

	return { items: result };
};

const CardListRendering = (props: CardListRenderingProps): JSX.Element => {
	const data = useComponentProps<SitecoreRawItems>(props.rendering.uid);

	const gridProps: GridProps = {
		variant: getVariant(props.params?.Variant),
	};

	const handleCardProps = (card: SitecoreCardRawProps) => {
		if (card.button.text) {
			const button = {
				label: card.button.text,
				href: card.button.url,
			};
			return button;
		}

		return undefined;
	};

	const handleCardSecondaryButton = (card: SitecoreCardRawProps) => {
		if (card.button?.text) {
			return (
				<ButtonCardSecondary
					href={card.button.url}
					target={card.button.target}
					whiteFontColor={card.whiteFontColor.value}
					buttonCustomWidth={card.buttonWidth.value}
				>
					{card.button.text}
				</ButtonCardSecondary>
			);
		}

		return undefined;
	};

	const getCardProps = (card: SitecoreCardRawProps) => {
		const cardProps: CardProps = {
			variant:
				card.variant.value.toLowerCase() === CardVariant.Secondary
					? CardVariant.Secondary
					: CardVariant.Primary,
			title: <RichText field={card.title} />,
			text: <RichText field={card.text} />,
			notes: <RichText field={card.notes} />,
			image: card.image.src ? <SitecoreImage field={card.image} /> : undefined,

			link: card?.link?.jsonValue?.value?.href,
			button: handleCardProps(card),
			buttonCustomWidth: card.buttonWidth.value,
			whiteFontColor: card.whiteFontColor.value,
			openInANewTab: (card.button?.target ?? card.link?.jsonValue?.value?.target ?? '') === '_blank',
		};

		const cardPrimaryProps: CardProps = { ...cardProps, button: handleCardProps(card) };
		const cardSecondaryProps: CardSecondaryProps = { ...cardProps, button: handleCardSecondaryButton(card) };

		if (card.variant.value.toLowerCase() === CardVariant.Secondary)
			return <CardSecondary {...cardSecondaryProps} />;

		return <Card {...cardPrimaryProps} />;
	};

	return <Grid {...gridProps}>{data?.items.results.map((card) => getCardProps(card))}</Grid>;
};

export default withDatasourceCheck()<CardListRenderingProps>(CardListRendering);
