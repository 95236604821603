import { FC } from 'react';
import { NutritionWrapper } from './NutritionWrapper';
import styled from 'styled-components';

const List = styled.ol`
	list-style: none;
	margin: 48px auto 0;
	max-width: 63vw;

	li:not(:first-child) {
		margin: 48px 0 0;
	}

	@media (max-width: 768px) {
		max-width: 100%;
		padding: 0;
	}
`;
const Item = styled.div`
	display: flex;
`;
const ItemBullet = styled.div`
	width: 52px;
	height: 52px;
	background: url(https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/static-pages/num-circle.png)
		center no-repeat;
	background-size: contain;
	font-family: 'jenna-sue', sans-serif;
	color: ${({ theme }) => theme.colors.primary.medium};
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 34px;
	font-weight: bold;
	padding: 8px 0 0;
`;
const ItemContent = styled.div`
	margin: 0 0 0 32px;
	flex: 1;

	@media (max-width: 768px) {
		margin: 0 0 0 16px;
	}
`;

const ItemTitle = styled.div`
	margin: 0;
	text-transform: uppercase;
	font-size: 26px;
	font-family: Roboto-Condensed-Bold, sans-serif;
	color: #2c2c2b;

	@media (max-width: 768px) {
		font-size: 24px;
	}
`;

const ItemText = styled.div`
	font-family: Roboto, sans-serif;
	font-weight: 400;
	max-width: 100%;
	text-align: left;
	font-size: 18px;
	margin: 24px 0 0;

	@media (max-width: 768px) {
		font-size: 16px;
	}
`;

const Title = styled.div`
	font-family: BigBloke;
	font-size: 44px;
	font-weight: 400;
	color: #891a1c;
	text-transform: uppercase;
	text-align: center;

	@media (max-width: 768px) {
		font-size: 32px;
	}
`;

const Subtitle = styled.div`
	font-family: Roboto, sans-serif;
	font-size: 18px;
	font-weight: 400;
	color: #2c2c2b;
	text-align: center;
	margin-top: 24px;
	padding: 0 28vh;

	@media (max-width: 768px) {
		font-size: 16px;
		padding: 0;
	}
`;

const items = [
	{
		title: 'Choose the grilled cooking style',
		text: 'Seafood, steak, and chicken cooked on our grill go without added butter or oil.',
	},
	{
		title: 'Go For Lower Sodium',
		text: 'Order "plain" and ask for seasoning to be placed on the side when you get proteins and veggies, which are hand-seasoned by our kitchen. You\'ll be in control of the sodium amount.',
	},
	{
		title: 'Choose a Light Dressing',
		text: "Our Light Balsamic Vinaigrette and Tangy Tomato Dressing are the choices you want to go for in making a lower calorie pick. If you just can't say no to our Ranch or Caesar creamy dressings, though, ask your waiter to put it on the side - so you can control just how much is added.",
	},
	{
		title: 'No rules, just right',
		text: 'Outback is committed to fixing your concerns. Whether you want dressing on the side or veggies over fries, all you have to do is ask.',
	},
	{
		title: 'Make room in the fridge',
		text: "Sometimes, a meal is big enough to be split in two. Take half your dinner home and it's tomorrow's lunch. Cut the meal's total calories in two just like that.",
	},
	{
		title: 'Piece together a dream meal',
		text: "There's no end to creating the perfect combo of our beef, seafood, chicken and shellfish in making a full and healthy dish. Let us suggest the wood-fire grilled shrimp, topped with BBQ sauce, and served with a plain sweet potato. Low-cal, high quality!",
	},
];

export const SmartDining: FC = () => {
	return (
		<NutritionWrapper activeLink={0}>
			<Title>Wholesome Habits at Outback</Title>
			<Subtitle>
				At our restaurant, we know how to make wholesome choices while maintaining great taste, fun, and
				tradition. Take these tips from our Registered Dietitian.
				<br />
			</Subtitle>

			<List>
				{items.map(({ title, text }, i) => (
					<li key={i}>
						<Item>
							<ItemBullet>{i + 1}</ItemBullet>
							<ItemContent>
								<ItemTitle>{title}</ItemTitle>
								<ItemText>{text}</ItemText>
							</ItemContent>
						</Item>
					</li>
				))}
			</List>
		</NutritionWrapper>
	);
};
