import { Field, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { NewsletterBlock, NewsletterBlockProps } from '@maverick/cms';
import { ComponentProps } from 'lib/component-props';

type NewsletterFormRenderingProps = ComponentProps & {
	fields: {
		Title: Field<string>;
		'Agreement Text': Field<string>;
		'Response Message': Field<string>;
	};
};
const NewsletterFormRendering = (props: NewsletterFormRenderingProps): JSX.Element => {
	const newsletterBlockProps: NewsletterBlockProps = {
		title: props.fields?.Title.value ?? '',
		agreementText: props.fields['Agreement Text']?.value ?? '',
		responseMessage: props.fields['Response Message']?.value ?? '',
	};

	return <NewsletterBlock {...newsletterBlockProps} />;
};

export default withDatasourceCheck()<NewsletterFormRenderingProps>(NewsletterFormRendering);
