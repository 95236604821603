import { gql } from 'graphql-request';

export const GetCatalogItens = gql`
	query GetItem($datasource: String!, $language: String!) {
		item(path: $datasource, language: $language) {
			children(first: 100) {
				results {
					name
					... on _CatalogItem {
						title {
							value
						}
						subtitle {
							value
						}
						description {
							value
						}
						note {
							value
						}
						firstButton {
							text
							target
							url
						}
						secondButton {
							text
							target
							url
						}
						image {
							alt
							src
						}
						variant {
							value
						}
						verticalView {
							value
						}
						buttonVariant {
							value
						}
					}
				}
			}
		}
	}
`;
