import { FC } from 'react';
import { NutritionWrapper } from './NutritionWrapper';
import styled from 'styled-components';
import { OutbackRedButton } from '@maverick/themes';

const ButtonWrapper = styled.div`
	margin: 24px 0 0;
	display: flex;
	justify-content: center;
`;

const Faq = styled.section`
	margin: 52px 0 0;
	padding: 0 20vh;

	@media (max-width: 768px) {
		padding: 0;
	}
`;

const List = styled.div`
	margin: 32px 0 0;
	display: flex;
	gap: 40px;

	@media (max-width: 768px) {
		display: block;
	}
`;
const ListColumn = styled.div`
	flex: 1;

	@media (max-width: 768px) {
		&:not(:first-child) {
			margin: 32px 0 0;
		}
	}
`;
const Item = styled.div`
	&:not(:first-child) {
		margin: 32px 0 0;
	}
`;
const ItemQuestion = styled.h6`
	text-transform: uppercase;
	font-size: 1.2rem;
	margin: 0;
`;
const ItemAnswer = styled.p`
	margin: 0;
	letter-spacing: 0.2px;

	a {
		text-decoration: underline;

		&:hover {
			color: ${({ theme }) => theme.colors.primary.medium};
		}
	}
`;

const list = [
	{
		question: 'How does Outback Steakhouse create its gluten-friendly menu?',
		answer: 'Our Registered Dietitians and Culinary team review each ingredient carefully to ensure that it can be used to create flavorful gluten-friendly dishes. From there, we work with our training team to teach the importance of understanding what gluten is, how to prepare gluten-friendly menu items, and best ways to serve the needs of our guests. It’s important that we execute our gluten-friendly menu items flawlessly and understand the needs of our guests from the minute they walk in the door to when they take their last bite.',
	},
	{
		question: "Are Outback's seasonings gluten-friendly?",
		answer: 'Yes. All Outback seasonings are gluten-friendly.',
	},
	{
		question: 'Does Outback have a gluten-free kitchen?',
		answer: 'We strive to offer gluten-friendly menu options, but do not have gluten-free kitchens. Because our dishes are prepared-to-order, during normal kitchen operations, individual foods may come into contact with one another due to shared cooking and preparation areas. Thus, we cannot guarantee that cross-contact with foods containing gluten will not occur; however, we take every step possible to prevent this.',
	},
	{
		question: 'How does Outback reduce the risk of cross-contact with Gluten?',
		answer: 'Outback provides gluten-friendly education to all of its employees and has a protocol for each time a gluten-friendly menu item is ordered. This includes: hand washing, glove changing, using clean cooking equipment and utensils, and special ordering instructions for our chefs.',
	},
	{
		question: "Are Outback's gluten-friendly items certified as gluten-free?",
		answer: 'The menu items do not have a gluten-free certification. However, our registered dietitian works closely with our chefs and supply chain to validate our gluten-friendly ingredients and menus.',
	},
];

export const GlutenFriendly: FC = () => {
	const pivot = Math.ceil(list.length / 2);
	const left = list.slice(0, pivot);
	const right = list.slice(pivot);

	return (
		<NutritionWrapper activeLink={1}>
			<h1>Gluten Friendly Menu Items</h1>
			<h4>
				Outback Steakhouse is serious about offering gluten friendly options. For over a decade we've designed
				options for those who need and chose to eat gluten-friendly. Our Registered Dietitian works closely with
				our chefs to create a variety of gluten-friendly options including - salads, steaks, chicken, seafood,
				ribs, fresh vegetables and our famous gluten-free dessert the Thunder from Down Under - with the same
				care and quality as every menu item we create.
			</h4>
			<ButtonWrapper>
				<OutbackRedButton
					href='https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/static-pages/Outback-Gluten-Free-Menu.pdf'
					target='_blank'
				>
					Download the gluten-friendly menu PDF
				</OutbackRedButton>
			</ButtonWrapper>

			<Faq>
				<h1>Gluten-Friendly FAQ</h1>
				<h4>
					We know you have questions, and we have answers. Here are our frequently asked questions answered by
					our Registered Dietitians.
				</h4>

				<List>
					<ListColumn>
						{left.map(({ question, answer }, i) => (
							<Item key={i}>
								<ItemQuestion>{question}</ItemQuestion>
								<ItemAnswer dangerouslySetInnerHTML={{ __html: answer }} />
							</Item>
						))}
					</ListColumn>

					<ListColumn>
						{right.map(({ question, answer }, i) => (
							<Item key={i}>
								<ItemQuestion>{question}</ItemQuestion>
								<ItemAnswer dangerouslySetInnerHTML={{ __html: answer }} />
							</Item>
						))}
					</ListColumn>
				</List>
			</Faq>
		</NutritionWrapper>
	);
};
