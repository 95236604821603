import { withDatasourceCheck, LinkField, Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { TopMessage } from '@maverick/cms';
import { ComponentProps } from 'lib/component-props';

type TopMessageRenderingProps = ComponentProps & {
	fields: {
		Link: LinkField;
		'Message Mobile': Field<string>;
	};
};

const TopMessageRendering = (props: TopMessageRenderingProps): JSX.Element => {
	return (
		<TopMessage
			link={{
				href: props?.fields?.Link?.value?.href ?? '',
				text: props?.fields?.Link?.value?.text ?? '',
				target: props?.fields?.Link?.value?.target ?? '_blank',
			}}
			messageMobile={
				props?.fields?.['Message Mobile'].value === ''
					? props?.fields?.Link?.value?.text ?? ''
					: props?.fields?.['Message Mobile'].value
			}
		/>
	);
};

export default withDatasourceCheck()<TopMessageRenderingProps>(TopMessageRendering);
