import { FC, useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { SteakStoryThermometer } from './components';

export interface SteakStoryCookStage {
	id: string;
	title: string;
	subtitle?: string;
}

const stages: Array<SteakStoryCookStage> = [
	{ id: 'main', title: 'Scroll to cook' },
	{ id: 'rare', title: 'Rare', subtitle: 'Cool red center' },
	{ id: 'medium-rare', title: 'Medium rare', subtitle: 'Warm red center' },
	{ id: 'medium', title: 'Medium', subtitle: 'Warm red center<br />touch of red' },
	{ id: 'medium-well', title: 'Medium well', subtitle: 'Warm brown<br />pink center' },
	{ id: 'well-done', title: 'Well done', subtitle: 'Hot brown center<br />no pink' },
];

const frameCount = 35;

const titleIn = keyframes`
    from { translate: 0 20px; opacity: 0; }
    to { translate: 0 0; opacity: 1; }
`;

const Container = styled.section`
	position: relative;
	z-index: 2;
	height: 2649px;
`;

const Content = styled.div`
	padding: 100px 60px;

	@media (max-width: 768px) {
		padding: 100px 32px;
	}
`;

const Title = styled.div`
	text-align: center;
	color: ${({ theme }) => theme.colors.neutral.white};
	margin: 0;
	position: relative;
	z-index: 3;
	animation: ${titleIn} 0.2s ease-in-out forwards;

	h2 {
		font-size: 6rem;
		text-transform: uppercase;
		margin: 0;
	}

	p {
		margin: 0;
		font-size: 2rem;
		font-family: 'jenna-sue';
		display: none;
	}

	@media (max-width: 768px) {
		h2 {
			font-size: 3rem;
		}

		p {
			display: block;
		}
	}
`;

const CanvasWrapper = styled.div`
	height: 100vh;
	width: 100%;
	left: 0;
	right: 0;
	background: url(https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Steak-Story/steak-cook/steak-cook-1.webp)
		center no-repeat;
	background-size: cover;
`;
const Canvas = styled.canvas`
	width: 100%;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
`;

export const SteakStoryCook: FC = () => {
	const containerRef = useRef<HTMLDivElement>(null);
	const canvasWrapperRef = useRef<HTMLDivElement>(null);
	const canvasRef = useRef<HTMLCanvasElement>(null);

	const contextRef = useRef<CanvasRenderingContext2D | null>(null);
	const imgRef = useRef<HTMLImageElement>();

	const [stage, setStage] = useState<SteakStoryCookStage>(stages[0]);

	const [thermometerStart, setThermometerStart] = useState<number>(0);
	const [thermometerEnd, setThermometerEnd] = useState<number>(0);

	useEffect(() => {
		if (!canvasRef.current) return;

		contextRef.current = canvasRef.current.getContext('2d');
		imgRef.current = new Image();
	}, []);

	useEffect(() => {
		const onResize = () => {
			if (canvasRef.current && canvasWrapperRef.current) {
				canvasRef.current.width = canvasWrapperRef.current.clientWidth;
				canvasRef.current.height = canvasWrapperRef.current.clientHeight;
			}
		};

		onResize();
		window.addEventListener('resize', onResize);
		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, []);

	useEffect(() => {
		const onScroll = () => {
			if (!containerRef.current || !canvasWrapperRef.current) return;

			const html = document.documentElement;
			const scrolled = html.scrollTop;
			const windowHeight = window.innerHeight;
			const containerTop = containerRef.current.offsetTop;
			const containerHeight = containerRef.current.clientHeight;
			const start = containerRef.current.offsetTop;
			const end = start + containerHeight - windowHeight;

			setThermometerStart(start);
			setThermometerEnd(end);

			if (scrolled < start) {
				canvasWrapperRef.current.style.position = 'absolute';
				canvasWrapperRef.current.style.top = '0';
				canvasWrapperRef.current.style.bottom = 'unset';
			} else if (scrolled >= start && scrolled <= end) {
				canvasWrapperRef.current.style.position = 'fixed';
				canvasWrapperRef.current.style.top = '0';
				canvasWrapperRef.current.style.bottom = '0';
			} else {
				canvasWrapperRef.current.style.position = 'absolute';
				canvasWrapperRef.current.style.top = 'unset';
				canvasWrapperRef.current.style.bottom = '0';
			}

			if (scrolled > start && scrolled < end) {
				const innerScrolled = scrolled - containerTop;
				const scrollFraction = innerScrolled / (containerHeight - windowHeight);
				const frameIndex = Math.min(frameCount - 1, Math.ceil(scrollFraction * frameCount));

				requestAnimationFrame(() => updateImage(frameIndex + 1));

				const stageFractions = 1 / stages.length;

				if (scrollFraction < stageFractions * 1) setStage(stages[0]);
				else if (scrollFraction > stageFractions * 1 && scrollFraction < stageFractions * 2)
					setStage(stages[1]);
				else if (scrollFraction > stageFractions * 2 && scrollFraction < stageFractions * 3)
					setStage(stages[2]);
				else if (scrollFraction > stageFractions * 3 && scrollFraction < stageFractions * 4)
					setStage(stages[3]);
				else if (scrollFraction > stageFractions * 4 && scrollFraction < stageFractions * 5)
					setStage(stages[4]);
				else if (scrollFraction > stageFractions * 5) setStage(stages[5]);
			}
		};

		window.addEventListener('scroll', onScroll);
		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	}, []);

	const updateImage = (index: number) => {
		imgRef.current!.src = currentFrame(index);
		imgRef.current!.onload = () => {
			if (!canvasRef.current) return;
			const canvasWidth = canvasRef.current.clientWidth;
			const canvasHeight = canvasRef.current.clientHeight;

			const imgWidth = imgRef.current!.width;
			const imgHeight = imgRef.current!.height;

			const scale = Math.max(canvasWidth / imgWidth, canvasHeight / imgHeight);
			const newWidth = imgWidth * scale;
			const newHeight = imgHeight * scale;
			const x = canvasWidth / 2 - newWidth / 2;
			const y = canvasHeight / 2 - newHeight / 2;

			contextRef.current?.drawImage(imgRef.current!, x, y, newWidth, newHeight);
		};
	};

	const currentFrame = (index: number) =>
		`https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Steak-Story/steak-cook/steak-cook-${index}.webp`;

	return (
		<Container ref={containerRef}>
			<CanvasWrapper ref={canvasWrapperRef}>
				<Content>
					{stages.map((s) => {
						if (s === stage) {
							return (
								<Title key={s.id}>
									<h2>{s.title}</h2>
									{s.subtitle && <p>{s.subtitle.replace('<br />', ' ')}</p>}
								</Title>
							);
						}
						return null;
					})}
					<SteakStoryThermometer start={thermometerStart} end={thermometerEnd} stage={stage} />
				</Content>
				<Canvas ref={canvasRef} />
			</CanvasWrapper>
		</Container>
	);
};
