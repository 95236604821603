import styled from 'styled-components';

export const Container = styled.div`
	background: url(https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Steak-Story/steak-hero/steak-farm.webp)
		center no-repeat;
	background-size: cover;
	min-height: 100vh;
`;

export const Loading = styled.div<{ progress: number }>`
	position: fixed;
	top: 100px;
	left: 0;
	width: 100%;
	height: 12px;
	z-index: 3;

	@media (max-width: 768px) {
		top: 62px;
	}

	&::after {
		content: '';
		position: absolute;
		height: 100%;
		top: 0;
		left: 0;
		width: ${({ progress }) => progress * 100}%;
		z-index: 4;
		background: #931a1a;
		transition: all ease 0.2s;
	}
`;
