import { SeatingDetails } from '@maverick/cms';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { useRouter } from 'next/router';

const SeatingDetailsRendering = (): JSX.Element => {
	const router = useRouter();
	const { sitecoreContext } = useSitecoreContext();

	return (
		<SeatingDetails
			displayName={sitecoreContext.route?.displayName ?? ''}
			router={router}
			animatedLabel={false}
			product='Outback'
		/>
	);
};

export default SeatingDetailsRendering;
