import { LinkField, withDatasourceCheck, Field, RichText, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { JumbotronProps, Jumbotron, TextAlign, JumbotronWithImages } from '@maverick/cms';

type JumbotronRenderingProps = ComponentProps & {
	fields: {
		Title: Field<string>;
		Text: Field<string>;
		Image?: ImageField;
		'First Button': LinkField;
		'Second Button': LinkField;
		'First Note': Field<string>;
		'Second Note': Field<string>;
		'Invert Button Color': { value: boolean };
		'Content Alignment': Field<string>;
	};
};

const JumbotronRendering = (props: JumbotronRenderingProps): JSX.Element => {
	const Title = props?.fields?.Title;
	const Text = props?.fields?.Text;

	const handleContentAlignment = () => {
		const align = props?.fields?.['Content Alignment']?.value ?? TextAlign.Center;

		if (align === TextAlign.Left) return 'left';

		if (align === TextAlign.Right) return 'right';

		return 'center';
	};

	const jumbotronProps: JumbotronProps = {
		title: !!Title?.value ? <RichText field={Title} /> : undefined,
		text: !!Text?.value ? <RichText field={Text} /> : undefined,
		button1: {
			href: props.fields['First Button']?.value?.href ?? '/',
			text: props.fields['First Button']?.value?.text ?? '',
			target: props.fields['First Button']?.value?.target ?? '',
		},
		button2: {
			href: props.fields['Second Button']?.value?.href ?? '/',
			text: props.fields['Second Button']?.value?.text ?? '',
			target: props.fields['Second Button']?.value?.target ?? '',
		},
		firstNote: !!props.fields['First Note']?.value ? <RichText field={props.fields['First Note']} /> : undefined,
		secondNote: !!props.fields['Second Note']?.value ? <RichText field={props.fields['Second Note']} /> : undefined,
		invertButtonColor: props.fields['Invert Button Color']?.value.valueOf(),
		contentAlignment: handleContentAlignment(),
		isJumbotronList: false,
	};

	return props?.fields?.Image?.value?.src ? (
		<JumbotronWithImages image={props?.fields?.Image.value?.src || ''} jumbotronProps={jumbotronProps} />
	) : (
		<Jumbotron {...jumbotronProps} />
	);
};

export default withDatasourceCheck()<JumbotronRenderingProps>(JumbotronRendering);
