import { LateralBar } from '@maverick/cms';
import { SitecoreResultsResponse, TabNavigationResponse } from '@maverick/entity';
import {
	ComponentRendering,
	GetStaticComponentProps,
	LayoutServiceData,
	withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import Link from 'next/link';
import { TabNavigationService } from 'src/services/TabNavigation.service';

export const getStaticProps: GetStaticComponentProps = async (
	rendering: ComponentRendering,
	layoutData: LayoutServiceData
) => {
	const result = await TabNavigationService.GetTabNavigation<SitecoreResultsResponse<TabNavigationResponse>>(
		rendering?.dataSource ?? '',
		layoutData?.sitecore?.context?.language ?? 'en'
	);
	return { items: result.body.item.children.results };
};

type LateralBarRenderingProps = ComponentProps & {
	items: Array<TabNavigationResponse>;
};

const LateralBarRendering = (props: LateralBarRenderingProps): JSX.Element => {
	return <LateralBar Link={Link} itens={props.items} />;
};

export default withDatasourceCheck()<LateralBarRenderingProps>(LateralBarRendering);
