import { FC, useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { SteakStoryScrollCta } from './components';

const enter = keyframes`
	0% {
		translate: 0 40px;
		opacity: 0;
	}

	100% {
		translate: 0 0;
		opacity: 1;
	}
`;

const Container = styled.section`
	height: 100vh;
	background: url(https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Steak-Story/steak-hero/steak-farm.webp)
		center no-repeat;
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 1;

	@media (max-width: 768px) {
		background-position: 15% 10%;
		background-size: initial;
		align-items: initial;
		padding: 30vh 0 0;
	}

	@media (max-height: 700px) {
		padding: 20vh 0 0;
	}
`;

const Content = styled.div`
	color: ${({ theme }) => theme.colors.neutral.white};
	animation: ${enter} 0.8s forwards;

	h1 {
		text-align: center;
		text-transform: uppercase;
		font-size: 7rem;
		margin: 0;
	}

	p {
		text-align: center;
		font-size: 1.5rem;
		padding: 0 20%;
	}

	@media (max-width: 768px) {
		h1 {
			font-size: 4rem;
		}

		p {
			padding: 0 10%;
		}
	}
`;

const ScrollCta = styled.div`
	position: absolute;
	bottom: 15vh;
	margin: 0 auto;
`;

interface SteakStoryHeroProps {
	onScrollCtaClick: () => void;
}
export const SteakStoryHero: FC<SteakStoryHeroProps> = ({ onScrollCtaClick }) => {
	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const onScroll = () => {
			if (!containerRef.current) return;

			const containerEnd = containerRef.current.offsetTop + containerRef.current.clientHeight;
			const scrolled = document.documentElement.scrollTop;

			containerRef.current.style.opacity = scrolled > containerEnd ? '0' : '1';
		};

		window.addEventListener('scroll', onScroll);
		window.addEventListener('resize', onScroll);
		return () => {
			window.removeEventListener('scroll', onScroll);
			window.removeEventListener('resize', onScroll);
		};
	}, []);

	return (
		<Container ref={containerRef}>
			<Content>
				<h1>Steak, a love story</h1>
				<p>
					Our steaks are all grain fed, optimally aged and hand trimmed to deliver a steak that's the star of
					our kitchen.
				</p>
			</Content>
			<ScrollCta onClick={onScrollCtaClick} role='button'>
				<SteakStoryScrollCta label='Read All About It' />
			</ScrollCta>
		</Container>
	);
};
