import { FC, useRef, useEffect, useState } from 'react';
import { LocationData } from '@maverick/entity';
import { GoToWrapper } from './GoTo.wrapper';
import { useRouter } from 'next/router';

export const GoToLocation: FC = () => {
	const [url, setUrl] = useState<string>('');
	const router = useRouter();
	const routerReady = useRef<boolean>(false);

	useEffect(() => {
		const locationData = LocationData.Get();
		let url = 'https://locations.outback.com';

		if (locationData.yextUrl) {
			url += `/${locationData.yextUrl}`;
		} else {
			url += '/search';
		}

		setUrl(url);
	}, []);

	useEffect(() => {
		routerReady.current = router.isReady;
	}, [router]);

	if (!url) return null;

	const redirectMethod = () => {
		const interval = setInterval(() => {
			if (routerReady.current) {
				window.location.replace(url);
				clearInterval(interval);
			}
		}, 100);
	};

	return <GoToWrapper redirectMethod={redirectMethod} />;
};
