import { useIsMobile } from '@maverick/hooks';
import { Icon } from '@maverick/ui';
import { IconPauseCircle } from '@maverick/icons/dist/IconPauseCircle';
import { IconPlayCircle } from '@maverick/icons/dist/IconPlayCircle';
import { FC, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { SteakStoryButton } from './components';

const Container = styled.div`
	position: relative;
	z-index: 2;
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${({ theme }) => theme.colors.neutral.white};
	background: url(https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Steak-Story/steak-done-right/steak-done-right-image.webp)
		center no-repeat;
	background-size: cover;

	video {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		z-index: 3;
	}

	@media (max-width: 768px) {
		video {
			display: none;
		}
	}
`;
const Content = styled.div<{ show: boolean }>`
	position: relative;
	z-index: 4;

	h2 {
		text-transform: uppercase;
		font-size: 7rem;
		margin: 0;

		span {
			opacity: 0;
			transition: all ease 0.3s;

			${({ show }) =>
				show &&
				css`
					opacity: 1;

					&:nth-child(2) {
						transition-delay: 0.5s;
					}

					&:nth-child(3) {
						transition-delay: 1s;
					}
				`}
		}
	}

	p {
		margin: 32px 0 0;
		text-align: center;
		font-family: 'jenna-sue';
		font-size: 3rem;
		opacity: 0;
		transition: all ease 0.3s;
		transition-delay: 1.5s;

		${({ show }) =>
			show &&
			css`
				opacity: 1;
			`}
	}

	${({ show }) =>
		show &&
		css`
			${Button} {
				opacity: 1;
			}
		`}

	@media(max-width: 768px) {
		h2 {
			font-size: 4rem;
			text-align: center;

			span {
				display: block;
			}
		}

		p {
			font-size: 2rem;
		}
	}
`;
const Control = styled.button`
	position: absolute;
	z-index: 4;
	top: 120px;
	right: 30px;
	display: flex;
	align-items: center;
	cursor: pointer;
	background: transparent;
	border: 0;
	color: inherit;
	font-family: inherit;
	font-size: 1rem;

	&:hover {
		opacity: 0.8;
	}

	@media (max-width: 768px) {
		display: none;
	}
`;
const ControlIcon = styled.div`
	width: 24px;
	height: 24px;
	margin: 0 12px 0 0;
`;
const ControlLabel = styled.div``;

const Button = styled.div`
	margin: 24px 0 0;
	display: flex;
	justify-content: center;
	opacity: 0;
	transition: all ease 0.3s;
	transition-delay: 1.75s;
`;

export const SteakStoryDoneRight: FC = () => {
	const containerRef = useRef<HTMLDivElement>(null);
	const videoRef = useRef<HTMLVideoElement>(null);

	const [showContent, setShowContent] = useState<boolean>(false);
	const [playing, setPlaying] = useState<boolean>(false);

	const isMobile = useIsMobile(768);

	useEffect(() => {
		const onScroll = () => {
			if (!containerRef.current || !videoRef.current) return;

			const scrolled = document.documentElement.scrollTop;
			const containerTop = containerRef.current.offsetTop;
			const windowHeight = window.innerHeight;

			const trigger = containerTop - windowHeight / 2;

			if (scrolled > trigger && isMobile) {
				setShowContent(true);
			} else {
				if (isMobile) {
					setShowContent(false);
				}
			}
		};

		window.addEventListener('scroll', onScroll);
		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	}, [isMobile]);

	useEffect(() => {
		if (!videoRef.current) return;

		videoRef.current.onpause = () => {
			setPlaying(false);
		};

		videoRef.current.onplay = () => {
			setPlaying(true);
			setShowContent(false);
		};

		videoRef.current.onended = () => {
			setShowContent(true);
		};
	}, []);

	const controlIcon = playing ? IconPauseCircle : IconPlayCircle;
	const controlLabel = playing ? 'Pause video' : 'Play video';
	return (
		<Container ref={containerRef}>
			<video muted ref={videoRef} preload={isMobile ? 'none' : 'auto'} autoPlay={false}>
				<source
					src='https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Steak-Story/steak-done-right/steak-done-right.mp4'
					type='video/mp4'
				/>
			</video>
			<Content show={showContent}>
				<h2>
					<span>Steak</span> <span>done</span> <span>right.</span>
				</h2>
				<p>Everyday since 1988</p>
				<Button>
					<SteakStoryButton label='Get on the waitlist' link='/seating' />
				</Button>
			</Content>
			<Control onClick={() => (videoRef.current?.paused ? videoRef.current?.play() : videoRef.current?.pause())}>
				<ControlIcon>
					<Icon icon={controlIcon} customSize />
				</ControlIcon>
				<ControlLabel>{controlLabel}</ControlLabel>
			</Control>
		</Container>
	);
};
