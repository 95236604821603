import {
	RichText,
	Field,
	Image as SitecoreImage,
	withDatasourceCheck,
	ImageField,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { Card, CardProps, CardVariant } from '@maverick/ui';
import { ComponentProps } from 'lib/component-props';
import { ButtonCardSecondary, CardSecondary, CardSecondaryProps } from '@maverick/cms';

type CardRenderingProps = ComponentProps & {
	fields: {
		Title: Field<string>;
		Text: Field<string>;
		Notes?: Field<string>;
		Link?: { value: { href: string; target: string } };
		Image?: ImageField;
		Button?: { value: { text: string; href: string; target: string } };
		ButtonWidth?: { value: string };
		WhiteFontColor?: { value: boolean };
	};
	params: {
		Variant: string;
	};
};

const CardRendering = (props: CardRenderingProps): JSX.Element => {
	const { Title, Button, Text, Image, Notes, ButtonWidth, WhiteFontColor, Link } = props.fields;

	const handleCardProps = () => {
		if (Button?.value.text) {
			const button = {
				label: Button.value.text,
				href: Button.value.href,
			};
			return button;
		}

		return undefined;
	};

	const handleCardSecondaryButton = () => {
		if (Button?.value.text) {
			return (
				<ButtonCardSecondary
					href={Button.value.href}
					target={Button.value.target}
					whiteFontColor={WhiteFontColor?.value ?? false}
					buttonCustomWidth={ButtonWidth?.value ?? 'auto'}
				>
					{Button.value.text}
				</ButtonCardSecondary>
			);
		}

		return undefined;
	};

	const cardProps: CardProps = {
		variant: props.params?.Variant === 'Secondary' ? CardVariant.Secondary : CardVariant.Primary,
		title: <RichText field={Title} />,
		text: <RichText field={Text} />,
		notes: <RichText field={Notes} />,
		image: Image?.value?.src ? <SitecoreImage field={Image} /> : undefined,
		link: Link?.value?.href,
		buttonCustomWidth: ButtonWidth?.value,
		whiteFontColor: WhiteFontColor?.value,
		openInANewTab: (Button?.value?.target ?? Link?.value?.target ?? '') === '_blank',
	};

	const cardPrimaryProps: CardProps = { ...cardProps, button: handleCardProps() };
	const cardSecondaryProps: CardSecondaryProps = { ...cardProps, button: handleCardSecondaryButton() };

	if (props.params?.Variant === 'Secondary') return <CardSecondary {...cardSecondaryProps} />;

	return <Card {...cardPrimaryProps} />;
};

export default withDatasourceCheck()<CardRenderingProps>(CardRendering);
