import { gql } from 'graphql-request';

export const GetSlides = gql`
	query GetSlides($datasource: String!, $language: String!) {
		item(path: $datasource, language: $language) {
			children {
				results {
					... on Slide {
						id
						name
						image {
							src
							alt
						}
						link {
							jsonValue
						}
					}
				}
			}
		}
	}
`;
