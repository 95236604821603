import { RichText, Field, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { JSXRichText } from '@maverick/cms';
import { ComponentProps } from 'lib/component-props';

type RichTextRenderingProps = ComponentProps & {
	fields: {
		Title: Field<string>;
		Text: Field<string>;
		Subtitle: Field<string>;
	};
};

const RichTextRendering = ({ fields }: RichTextRenderingProps): JSX.Element => {
	const title = !!fields.Title?.value ? <RichText field={fields.Title} /> : undefined;
	const text = !!fields.Text?.value ? <RichText field={fields.Text} /> : undefined;
	const subtitle = !!fields.Subtitle?.value ? <RichText field={fields.Subtitle} /> : undefined;
	return <JSXRichText title={title} text={text} subtitle={subtitle} />;
};

export default withDatasourceCheck()<RichTextRenderingProps>(RichTextRendering);
