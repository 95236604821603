import { GraphQLRequestClient } from '@sitecore-jss/sitecore-jss-nextjs';

const LANGUAGE = 'en';
const PATH = '/sitecore/content/BBI/outback/Home/press-room';

export interface Article {
	title: string;
	date: string;
	url: string;
}

interface SitecoreRawArticle {
	item: {
		children: SitecoreRawChildren;
	};
}
interface SitecoreRawChildren {
	results: {
		url: {
			path: string;
		};
		fields: {
			name: string;
			value: string;
		}[];
	}[];
}

export const GetArticles = async (): Promise<Array<Article>> => {
	const result = await Request(getArticlesGraphQL);
	const articles = new Array<Article>();
	GetChildren(result.item.children, articles);
	return articles.sort(CompareDesc);
};

const GetChildren = (children: SitecoreRawChildren, articles: Array<Article>) => {
	children?.results.forEach((child) => {
		const article: Article = {
			title: child.fields.find((x) => x.name == 'Title')?.value ?? '',
			date: child.fields.find((x) => x.name == 'Date')?.value ?? '',
			url: child.url.path,
		};
		articles.push(article);
	});
};

const Request = async (gql: string): Promise<SitecoreRawArticle> => {
	const gqlClient = new GraphQLRequestClient(String(process.env.GRAPH_QL_ENDPOINT), {
		apiKey: process.env.SITECORE_API_KEY,
	});
	return await gqlClient.request<SitecoreRawArticle>(gql);
};

const getArticlesGraphQL = `
query{
    item(path:"${PATH}", language: "${LANGUAGE}") {
        children(first:50, includeTemplateIDs: "{2BADB8EF-D64A-43A2-8076-08839F3BBCB8}"){
            results{
                url{
                    path
                }
                fields{
                    name,
                    value
                }
            }
        }
    }
}
`;

function CompareDesc(a: Article, b: Article) {
	if (a.date < b.date) {
		return 1;
	}
	if (a.date > b.date) {
		return -1;
	}
	return 0;
}
