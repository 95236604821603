import styled from 'styled-components';

export const Container = styled.div`
	padding-top: 52px;
`;

export const Item = styled.div`
	padding-bottom: 52px;
	text-transform: uppercase;
	font-weight: bold;
`;

export const Date = styled.div`
	padding-bottom: 20px;
	color: ${({ theme }) => theme.colors.primary.medium};
`;

export const Link = styled.a`
	font-size: 1.8rem;
	border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.black};
	cursor: pointer;
`;
