import { gql } from 'graphql-request';

export const GetMenuGraphQL = gql`
	query GetItemMenu($datasource: String!, $language: String!) {
		item(path: $datasource, language: $language) {
			children(first: 100) {
				results {
					... on _MenuItem {
						title {
							value
							jsonValue
						}
						subTitle {
							value
							jsonValue
						}
						description {
							value
							jsonValue
						}
						link {
							url
							target
							text
						}
						image {
							alt
							src
						}
						isNew {
							value
							jsonValue
						}
					}
				}
			}
		}
	}
`;
