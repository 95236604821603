import {
	GetStaticComponentProps,
	withDatasourceCheck,
	useComponentProps,
	ComponentRendering,
	LayoutServiceData,
	useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { MenuNavigation, MenuNavigationItemProps, MenuNavigationProps } from '@maverick/cms';
import Link from 'next/link';
import { SitecoreResultsResponse, MenuNavigationResponse } from '@maverick/entity';
import { MenuNavigationService } from 'src/services/MenuNavigation.service';

type MenuNavigationRenderingProps = ComponentProps & SitecoreResultsResponse<MenuNavigationResponse>;

type SitecoreRawItems = {
	items: MenuNavigationResponse[];
};

export const getStaticProps: GetStaticComponentProps = async (
	rendering: ComponentRendering,
	layoutData: LayoutServiceData
) => {
	const result = await MenuNavigationService.GetMenuNavigation<SitecoreResultsResponse<MenuNavigationResponse>>(
		rendering?.dataSource ?? '',
		layoutData?.sitecore?.context?.language ?? 'en'
	);

	return { items: result.body.item.children.results };
};

const MenuNavigationRendering = (props: MenuNavigationRenderingProps): JSX.Element => {
	const data = useComponentProps<SitecoreRawItems>(props.rendering.uid);
	const context = useSitecoreContext();

	const menuNavigation: MenuNavigationProps = {
		items: Array<MenuNavigationItemProps>(),
		currentPage: context.sitecoreContext.itemPath as string,
	};

	data?.items?.map((item) => {
		let menuItem: MenuNavigationItemProps = {
			path: item?.url?.path,
			text: item?.displayName,
			isActive: false,
			Link: Link,
		};
		menuNavigation.items!.push(menuItem);
	});

	return <MenuNavigation items={menuNavigation.items} currentPage={menuNavigation.currentPage} />;
};

export default withDatasourceCheck()<MenuNavigationRenderingProps>(MenuNavigationRendering);
