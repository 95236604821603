import { Seating } from '@maverick/cms';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { useRouter } from 'next/router';

const SeatingRendering = (): JSX.Element => {
	const { sitecoreContext } = useSitecoreContext();
	const router = useRouter();
	return (
		<Seating
			router={router}
			urlLocation='https://locations.outback.com/search'
			product='Outback'
			routeDisplayName={sitecoreContext.route?.displayName ?? ''}
		/>
	);
};

export default SeatingRendering;
