import { FC } from 'react';
import { Field, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { SkipLink } from '@maverick/ui';

type skipLinkRenderingProps = ComponentProps & {
	fields: {
		Text: Field<string>;
		'Skip To': Field<string>;
	};
};

const SkipLinkRendering: FC<skipLinkRenderingProps> = ({ fields }) => {
	return <SkipLink text={fields?.Text?.value ?? ''} link={fields?.['Skip To']?.value ?? ''} />;
};

export default withDatasourceCheck()<skipLinkRenderingProps>(SkipLinkRendering);
