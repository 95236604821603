import styled from 'styled-components';

export const Container = styled.div`
	background: #f6f2e8;
`;

export const Hero = styled.section`
	background: url(https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Tailgate/tailgate-hero.webp)
		center no-repeat;
	background-size: cover;
	height: 400px;
`;

export const Content = styled.div`
	color: rgb(51, 51, 51);
`;

export const ContentHolder = styled.div``;

export const Main = styled.header`
	padding: 32px 0 0;
	width: 56vw;
	margin: 0 auto;

	h1 {
		margin: 0;
		font-family: 'IntroRust', sans-serif;
		font-size: 3rem;
		text-align: center;
		line-height: 1;

		span {
			color: #a10000;
		}
	}

	& > div {
		margin: 24px 0 56px;
		text-transform: uppercase;
		font-size: 1.2rem;
		display: flex;
		justify-content: center;

		a {
			text-decoration: underline;
			display: block;
			color: #a10000;

			&:hover {
				opacity: 0.7;
			}
		}
	}

	@media (max-width: 768px) {
		width: unset;
		padding: 32px 0;

		h1 {
			font-size: 2rem;
		}

		& > div {
			font-size: 1.1rem;
			white-space: nowrap;
			flex-wrap: wrap;
			line-height: 2;
		}
	}
`;

export const MainSplit = styled.div`
	padding: 0 24px;

	@media (max-width: 768px) {
		padding: 0 12px;
	}
`;

export const Meet = styled.div`
	width: 56vw;
	margin: -100px auto 0;
	padding: 100px 0 56px;

	h2 {
		font-size: 2.1rem;
		font-family: 'IntroRust', sans-serif;
		text-align: center;

		span {
			color: #a10000;
		}
	}

	p {
		margin: 32px 0 0;
		text-align: center;
		font-size: 1.3rem;
	}

	@media (max-width: 768px) {
		margin: -132px 0 0;
		width: unset;

		h2 {
			margin: 0;
			font-size: 1.6rem;
		}

		p {
			font-size: 1rem;
		}
	}
`;

export const Teammates = styled.div`
	padding: 32px 0;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 32px;

	@media (max-width: 768px) {
		grid-template-columns: 1fr;
	}
`;

export const TeammateCard = styled.div`
	border: 4px solid;
	padding: 8px;
	background: #fff;
	display: flex;
	flex-direction: column;
`;

export const TeammateCardContent = styled.div`
	padding: 24px;
	flex: 1;

	@media (max-width: 768px) {
		padding: 12px;
	}
`;

export const TeammateCardName = styled.h3`
	margin: 0;
	font-family: 'IntroRust', sans-serif;
	font-size: 1.4rem;
	color: #a10000;
`;

export const TeammateCardInfo = styled.p`
	margin: 16px 0 0;
	font-size: 1.2rem;
`;

export const TeammateCardText = styled.p`
	margin: 20px 0 0;
	font-size: 1.1rem;
`;

export const TeammateCardQuote = styled.em`
	display: block;
	margin: 32px 0 0;
	font-size: 1.2rem;
`;

export const TeammateCardHandler = styled.a`
	display: block;
	margin: 32px 0 0;
	font-size: 1.2rem;
	text-decoration: underline;

	&:hover {
		opacity: 0.8;
	}
`;

export const TeammateCardPhoto = styled.img`
	display: block;
	width: 100%;
	margin: 32px 0 0;
`;

export const Catering = styled.div`
	padding: 32px 0;

	a {
		display: block;
	}

	img {
		display: block;
		width: 100%;
	}
`;

export const Apply = styled.section`
	margin: -100px 0 0;
	padding: 100px 0 0;
`;

export const Alumni = styled.section`
	margin: -100px 0 0;
	padding: 100px 0 60px;

	h2 {
		text-align: center;
		font-size: 2.4rem;
		font-family: 'IntroRust', sans-serif;

		span {
			color: #a10000;
		}
	}

	@media (max-width: 768px) {
		h2 {
			font-size: 1.6rem;
		}
	}
`;

export const AlumniList = styled.div`
	margin: 64px 0 0;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 48px;

	@media (max-width: 1200px) {
		grid-template-columns: 1fr 1fr;
	}

	@media (max-width: 768px) {
		grid-template-columns: 1fr;
		gap: 32px;
	}
`;

export const AlumniCard = styled.div`
	background: #fff;
`;

export const AlumniCardContent = styled.div`
	padding: 12px;
`;

export const AlumniCardName = styled.h3`
	margin: 0;
	font-family: 'IntroRust', sans-serif;
	font-size: 1.2rem;
	color: #a10000;
`;

export const AlumniCardInfo = styled.p`
	margin: 0;
	font-size: 1.2rem;
`;

export const AlumniCardHandler = styled.a`
	display: block;
	margin: 20px 0 0;
	font-size: 1.2rem;
	text-decoration: underline;

	a:hover {
		opacity: 0.8;
	}
`;

export const AlumniCardPhoto = styled.img`
	display: block;
	margin: 20px 0 0;
	width: 100%;
`;
