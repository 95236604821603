import { FC, useState, useRef, useEffect } from 'react';
import { Slugify } from '@maverick/utils';
import { GoToWrapper } from './GoTo.wrapper';
import { LocationManager } from '@maverick/cms';
import { Config } from 'src/Config';
import { useRouter } from 'next/router';
import { useCookies } from '@maverick/hooks';
import { Cookies } from '@maverick/entity';

export const GoToOrder: FC = () => {
	const [url, setUrl] = useState<string>('');
	const router = useRouter();
	const routerReady = useRef<boolean>(false);
	const [favoriteStore] = useCookies(Cookies.FavoriteStore);

	useEffect(() => {
		const redirectToRestaurant = async () => {
			let url = Config.Urls.Order;
			if (!favoriteStore) {
				setUrl(url);
				return;
			}

			const restaurant = await LocationManager.GetLocationByRef(favoriteStore);
			if (!restaurant) {
				setUrl(url);
				return;
			}
			if (restaurant?.slug) {
				url += `/${restaurant.slug}`;
			} else if (restaurant?.id) {
				url += `/${restaurant.id}-${Slugify(restaurant.name)}`;
			}
			setUrl(url);
		};
		if (favoriteStore !== undefined) {
			redirectToRestaurant();
		}
	}, [favoriteStore]);

	useEffect(() => {
		routerReady.current = router.isReady;
	}, [router]);

	if (!url) return null;

	const redirectMethod = () => {
		const interval = setInterval(() => {
			if (routerReady.current) {
				window.location.replace(url);
				clearInterval(interval);
			}
		}, 100);
	};

	return <GoToWrapper redirectMethod={redirectMethod} url={url} />;
};
