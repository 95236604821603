import { gql } from 'graphql-request';

export const GetTabNavigation = gql`
	query GetItemTabNavigation($datasource: String!) {
		item(path: $datasource, language: "en") {
			children(first: 100, includeTemplateIDs: "E65397EB-D3EF-4002-8576-59D8C3C385CD") {
				results {
					displayName
					url {
						path
					}
				}
			}
		}
	}
`;
