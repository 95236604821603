import { Field, Placeholder, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import { Container, ContainerVariants } from '@maverick/cms';
import { ComponentProps } from 'lib/component-props';

export type ContainerRenderingProps = ComponentProps & {
	rendering: {
		params: {
			Variant: string;
			'Background Color': Field<string>;
			'Only Mobile': { value: boolean };
		};
		fields: {
			Desktop: ImageField;
			Mobile: ImageField;
		}
	};
};

const ContainerRendering = (props: ContainerRenderingProps): JSX.Element => {
	const color = props?.params['Background Color'] ?? '';
	const onlyMobile = !!props?.rendering?.params['Only Mobile'];
	const backgroundImageDesktop = props?.rendering?.fields?.Desktop?.value?.src ?? '';
	const backgroundImageMobile = props?.rendering?.fields?.Mobile?.value?.src ?? '';

	return (
		<>
			<Container
				variant={props?.rendering?.params?.Variant as ContainerVariants}
				backgroundColor={color}
				onlyMobile={onlyMobile}
				backgroundImage={backgroundImageDesktop}
				backgroundImageMobile={backgroundImageMobile}
				>
				{<Placeholder name='bbi-cms-container' rendering={props.rendering} />}
			</Container>
		</>
	);
};

export default ContainerRendering;
