import styled from 'styled-components';

export const Container = styled.div``;

export const Hero = styled.div`
	background: url(https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/International/international-hero.webp)
		center no-repeat;
	background-size: cover;
	height: 65vh;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	z-index: 0;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #0002;
		z-index: 1;
	}
`;

export const HeroContent = styled.div`
	position: relative;
	z-index: 2;
	padding: 0 16px;

	h1 {
		margin: 0;
		text-align: center;
		font-family: ${({ theme }) => theme.components.hero.title.fontFamily};
		font-weight: ${({ theme }) => theme.components.hero.title.fontWeight};
		font-size: ${({ theme }) => theme.components.hero.title.fontSizes.medium};
		line-height: ${({ theme }) => theme.components.hero.title.lineHeight.medium};
		color: ${({ theme }) => theme.components.hero.title.fontColor};
		text-transform: uppercase;

		@media (max-width: 768px) {
			font-size: ${({ theme }) => theme.components.hero.title.fontSizes.small};
			line-height: ${({ theme }) => theme.components.hero.title.lineHeight.small};
		}
		@media (max-width: 568px) {
			font-size: ${({ theme }) => theme.components.hero.title.fontSizes.smallest};
			line-height: ${({ theme }) => theme.components.hero.title.lineHeight.smallest};
		}
	}

	p {
		text-align: center;
		font-family: ${({ theme }) => theme.components.hero.text.fontFamily};
		font-size: ${({ theme }) => theme.components.hero.text.fontSizes.small};
		font-weight: ${({ theme }) => theme.components.hero.text.fontWeight};
		color: ${({ theme }) => theme.components.hero.title.fontColor};
		margin: 24px 0 0;

		@media (max-width: 768px) {
			font-size: ${({ theme }) => theme.components.hero.text.fontSizes.small};
		}

		@media (max-width: 568px) {
			font-size: ${({ theme }) => theme.components.hero.text.fontSizes.smallest};
			line-height: 1.5rem;
		}
	}
`;

export const Dropdown = styled.div`
	margin: 32px auto 0;
	width: 60%;

	select {
		text-transform: uppercase;
		font-family: ${({ theme }) => theme.components.hero.text.fontFamily};
	}
`;

export const Content = styled.div`
	position: relative;
	z-index: 3;
	background: ${({ theme }) => theme.colors.neutral.white};
	min-height: 70vh;
`;

export const List = styled.div``;

export const Item = styled.div`
	padding: 52px 0;
	border-bottom: 1px solid #e8dcc2;

	&:hover {
		background: #f6f2e8;
	}
`;

export const ItemContent = styled.div`
	display: flex;
	align-items: flex-start;
	/* justify-content: space-between; */
`;

export const ItemIcon = styled.div`
	width: 16px;
	padding: 4px 0 0;
	color: ${({ theme }) => theme.colors.primary.medium};
`;

export const ItemMain = styled.div`
	margin: 0 0 0 8px;

	h5 {
		font-size: 1.8rem;
		text-transform: uppercase;
		margin: 0;
	}
`;

export const SectionTitle = styled.div`
	margin: 16px 0 0;
	text-transform: uppercase;
	font-size: 1.1rem;
	font-weight: bold;
	border-bottom: 1px solid #e8dcc2;
	padding: 0 0 8px;
`;

export const SectionContent = styled.div`
	margin: 16px 0 0;
	font-size: 1.2rem;
	letter-spacing: 0.1px;
	line-height: 1.4;
	color: rgb(38, 37, 36);

	a {
		text-decoration: underline;

		&:hover {
			color: ${({ theme }) => theme.colors.primary.medium};
		}
	}
`;

export const RemainingSpace = styled.div<{ height: number }>`
	height: ${({ height }) => height}px;
	background: #e5c8a2;
`;
