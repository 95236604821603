import { Gallery, Holder } from '@maverick/ui';
import { FC } from 'react';
import Link from 'next/link';
import { Teammate, Teammates, Alumni, Alumnus } from './Tailgate.content';
import * as Styled from './Tailgate.styled';
import { GalleryVariant } from '@maverick/ui';
import { DottedLine, ImgType } from '@maverick/cms';

interface TeammateCardProps {
	teammate: Teammate;
}
const TeammateCard = ({ teammate }: TeammateCardProps) => (
	<Styled.TeammateCard>
		<Styled.TeammateCardContent>
			<Styled.TeammateCardName>{teammate.name}</Styled.TeammateCardName>
			<Styled.TeammateCardInfo>{teammate.info}</Styled.TeammateCardInfo>
			<Styled.TeammateCardText>{teammate.text}</Styled.TeammateCardText>
			<Styled.TeammateCardQuote>{teammate.quote}</Styled.TeammateCardQuote>
			<Styled.TeammateCardHandler href={teammate.handler.link} target='_blank'>
				{teammate.handler.label}
			</Styled.TeammateCardHandler>
		</Styled.TeammateCardContent>
		<Styled.TeammateCardPhoto
			src={`https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Tailgate/${teammate.photo}`}
			alt={teammate.name}
		/>
	</Styled.TeammateCard>
);

interface AlumniCardProps {
	alumnus: Alumnus;
}
const AlumniCard = ({ alumnus }: AlumniCardProps) => (
	<Styled.AlumniCard>
		<Styled.AlumniCardContent>
			<Styled.AlumniCardName>{alumnus.name}</Styled.AlumniCardName>
			<Styled.AlumniCardInfo>{alumnus.info}</Styled.AlumniCardInfo>
			<Styled.AlumniCardHandler href={alumnus.handler.link} target='_blank'>
				{alumnus.handler.label}
			</Styled.AlumniCardHandler>
		</Styled.AlumniCardContent>
		<Styled.AlumniCardPhoto
			src={`https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Tailgate/${alumnus.photo}`}
			alt={alumnus.name}
		/>
	</Styled.AlumniCard>
);

export const Tailgate: FC = () => {
	return (
		<>
			<Styled.Container>
				<Styled.Hero />

				<Styled.Content>
					<DottedLine img={ImgType.dottedLineWithBorder} onlyMobile={false} />
					<Holder>
						<Styled.ContentHolder>
							<Styled.Main>
								<h1>
									Welcome to <span>The Tailgate</span>
								</h1>
								<div>
									<Link href='#meet'>Meet the teammates</Link>
									<Styled.MainSplit>|</Styled.MainSplit>
									<Link href='#apply'>Apply to be a teammate</Link>
									<Styled.MainSplit>|</Styled.MainSplit>
									<Link href='#alumni'>Teammate alumni</Link>
								</div>
								<DottedLine img={ImgType.dottedLine} onlyMobile={false} />
							</Styled.Main>

							<Styled.Meet id='meet'>
								<h2>
									Meet the Outback <span>Teammates</span>
								</h2>
								<p>
									“Outback plays an important role in the communities where we are located, many of
									which are in close proximity to some of the top collegiate athletic programs in the
									country. One of the best parts about being on sports teams is the camaraderie that
									comes from it, and we are excited to continue our support of college athletes by
									giving teams a new option for their group dinners with our catering options.”
									<br />
									<em>~Brett Patterson, President, Outback Steakhouse</em>
								</p>
							</Styled.Meet>

							<DottedLine img={ImgType.dottedLine} onlyMobile={false} />

							<Styled.Teammates>
								{Teammates.map((t, i) => (
									<TeammateCard teammate={t} key={i} />
								))}
							</Styled.Teammates>

							<Styled.Catering>
								<Link href='/offers/catering'>
									<a>
										<img
											src='https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Tailgate/tailgate-catering.webp'
											alt='Steak It To The House - Order Catering'
										/>
									</a>
								</Link>
							</Styled.Catering>

							<Styled.Apply id='apply'>
								<Gallery
									variant={GalleryVariant.Primary}
									title={<span>Apply to be a teammate</span>}
									subtitle={''}
									text={
										<div style={{ paddingTop: '40px' }}>
											Are you a college athlete and interested in becoming an Outback TeamMATE?
											Applicants that are selected to be part of our TeamMATES program will
											receive exclusive merch and up to $500 in Outback gift cards.
										</div>
									}
									button={{
										label: 'Apply now',
										href: 'https://forms.office.com/r/iJDN8YaAPY',
										openInANewTab: true,
									}}
									image={
										<img
											src='https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Tailgate/teammate-apply.webp'
											style={{ width: '100%', height: '100%' }}
										/>
									}
								/>

								<DottedLine img={ImgType.dottedLine} onlyMobile={false} />
							</Styled.Apply>

							<Styled.Alumni id='alumni'>
								<h2>
									Outback Teammates <span>Alumni</span>
								</h2>
								<Styled.AlumniList>
									{Alumni.map((a, i) => (
										<AlumniCard alumnus={a} key={i} />
									))}
								</Styled.AlumniList>
							</Styled.Alumni>
						</Styled.ContentHolder>
					</Holder>
				</Styled.Content>
			</Styled.Container>
		</>
	);
};
