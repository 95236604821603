import { useIsOnScreen } from '@maverick/hooks';
import { useRef, forwardRef } from 'react';
import styled from 'styled-components';
import { SteakStoryNumText, SteakStoryTitle } from './components';
import { SteakStoryCanvas } from './components/SteakStoryCanvas';

const Container = styled.section`
	position: relative;
	z-index: 2;

	@media (max-width: 768px) {
		background: url(https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Steak-Story/steak-season/steak-season-bg.webp)
			center no-repeat;
		background-size: cover;
	}
`;
const Content = styled.div`
	position: relative;
	z-index: 3;
	padding: 200px 0;
	color: ${({ theme }) => theme.colors.neutral.white};
`;

export const SteakStorySeason = forwardRef<HTMLDivElement>((_, ref) => {
	const containerRef = useRef<HTMLDivElement>(null);

	const titleRef = useRef<HTMLDivElement>(null);
	const titleIsOnScreen = useIsOnScreen(titleRef, { rootMargin: '-10% 0px' });

	const text1Ref = useRef<HTMLDivElement>(null);
	const text1IsOnScreen = useIsOnScreen(text1Ref);

	const text2Ref = useRef<HTMLDivElement>(null);
	const text2IsOnScreen = useIsOnScreen(text2Ref);

	return (
		<Container ref={containerRef}>
			<Content ref={ref}>
				<SteakStoryTitle
					title={<>It's steak season</>}
					subtitle={<>Let's make it rain herbs and spices</>}
					ref={titleRef}
					isOnScreen={titleIsOnScreen}
				/>
				<SteakStoryNumText
					num='1'
					text={
						<>
							Over 35 years ago, our founders created a steak seasoning like no other. It is a signature
							blend of spices which creates the bold flavor you crave.
						</>
					}
					ref={text1Ref}
					isOnScreen={text1IsOnScreen}
				/>
				<SteakStoryNumText
					num='2'
					text={
						<>
							Our special blend of spices are rained down on the steak from 6 inches above for a nice even
							coating to deliver a bold, juicy bite every time.
						</>
					}
					ref={text2Ref}
					isOnScreen={text2IsOnScreen}
				/>
			</Content>
			<SteakStoryCanvas
				containerRef={containerRef}
				frameCount={98}
				framesBasePath='https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Steak-Story/steak-season/steak-season-'
			/>
		</Container>
	);
});

SteakStorySeason.displayName = 'SteakStorySeason';
