import { gql } from 'graphql-request';

export const getJumbotronList = gql`
	query GetJumbrotrons($datasource: String!) {
		item(path: $datasource, language: "en") {
			children(first: 100) {
				results {
					name
					... on _Jumbotron {
						title {
							value
							jsonValue
						}
						text {
							value
							jsonValue
						}
						firstButton {
							text
							target
							url
						}
						secondButton {
							text
							target
							url
						}
						firstNote {
							value
							jsonValue
						}
						secondNote {
							value
							jsonValue
						}
						contentAlignment {
							jsonValue
						}
					}
				}
			}
		}
	}
`;
