import { useIsOnScreen } from '@maverick/hooks';
import { FC, useRef } from 'react';
import styled from 'styled-components';
import { SteakStoryNumText, SteakStoryTitle } from './components';
import { SteakStoryCanvas } from './components/SteakStoryCanvas';

const Container = styled.section`
	position: relative;
`;
const Content = styled.div`
	position: relative;
	z-index: 3;
	color: ${({ theme }) => theme.colors.neutral.white};
	padding: 350px 0;

	@media (max-width: 768px) {
		background: url(https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Steak-Story/steak-perfection/steak-perfection-bg.webp)
			center no-repeat;
		background-size: cover;
		padding: 200px 0;
	}
`;

export const SteakStoryPerfection: FC = () => {
	const containerRef = useRef<HTMLDivElement>(null);

	const titleRef = useRef<HTMLDivElement>(null);
	const titleIsOnScreen = useIsOnScreen(titleRef, { rootMargin: '-10% 0px' });

	const item1Ref = useRef<HTMLDivElement>(null);
	const item1IsOnScreen = useIsOnScreen(item1Ref);

	const item2Ref = useRef<HTMLDivElement>(null);
	const item2IsOnScreen = useIsOnScreen(item2Ref);

	return (
		<Container ref={containerRef}>
			<Content>
				<SteakStoryTitle
					title={<>Coming of age</>}
					subtitle={<>Steak perfection takes time</>}
					isOnScreen={titleIsOnScreen}
					ref={titleRef}
				/>
				<SteakStoryNumText
					ref={item1Ref}
					isOnScreen={item1IsOnScreen}
					num='1'
					text={
						<>
							We take aging of our steaks very seriously. Each cut is aged until it reaches optimal
							tenderness.
						</>
					}
				/>
				<SteakStoryNumText
					ref={item2Ref}
					isOnScreen={item2IsOnScreen}
					num='2'
					text={
						<>
							The aging process helps unlock the natural juices and flavor of the steak. Its muscle fibers
							are gently broken down and that's how it gets the flavor you love.
						</>
					}
				/>
			</Content>
			<SteakStoryCanvas
				containerRef={containerRef}
				frameCount={142}
				framesBasePath={
					'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Steak-Story/steak-perfection/steak-perfection-'
				}
			/>
		</Container>
	);
};
