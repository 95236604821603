import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { Pod, PodLarge, PodProps, PodVariant } from '@maverick/cms';

type PodRenderingProps = ComponentProps & {
	rendering: {
		fields: {
			FirstLine: { value: string };
			SecondLine: { value: string };
			ThirdLine: { value: string };
			FourthLine: { value: string };
			ButtonText: { value: string };
			Description: { value: string };
			DesktopImage: { value: { src: string } };
			MobileImage: { value: { src: string } };
			Link: { value: { href: string; target: string; querystring: string } };
			Overlay: { value: boolean };
			'Keep content inside': { value: boolean };
		};
		params: {
			Variant: string;
		};
	};
};

const PodRendering = (props: PodRenderingProps): JSX.Element => {
	let titles: string[] = [];

	if (props?.rendering?.fields?.FirstLine?.value) titles.push(props.rendering.fields.FirstLine.value);

	if (props?.rendering?.fields?.SecondLine?.value) titles.push(props.rendering.fields.SecondLine.value);

	if (props?.rendering?.fields?.ThirdLine?.value) titles.push(props.rendering.fields.ThirdLine.value);

	if (props?.rendering?.fields?.FourthLine?.value) titles.push(props.rendering.fields.FourthLine.value);

	const buildLinkHref = () => {
		return `${props?.rendering?.fields?.Link?.value.href}${props?.rendering?.fields?.Link?.value.querystring}`;
	};

	const podProps: PodProps = {
		title: titles,
		button: props?.rendering?.fields?.ButtonText?.value,
		description: props?.rendering?.fields?.Description?.value,
		imageUrl: props?.rendering?.fields?.DesktopImage?.value.src,
		imageUrlMobile: props?.rendering?.fields?.MobileImage?.value.src,
		link: {
			href: buildLinkHref(),
			target: props?.rendering?.fields?.Link?.value.target,
		},
		overlay: props?.rendering?.fields?.Overlay?.value,
		keepContentInside: props?.rendering.fields?.['Keep content inside']?.value,
	};

	if (props?.params?.Variant === PodVariant.Large) return <PodLarge {...podProps} />;

	return <Pod {...podProps} variant={props?.params?.Variant as PodVariant} />;
};

export default withDatasourceCheck()<PodRenderingProps>(PodRendering);
