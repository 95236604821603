import {
	Field,
	GetStaticComponentProps,
	RichText,
	useSitecoreContext,
	withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { RewardsModalBlock } from '@maverick/cms';
import { ComponentProps } from 'lib/component-props';
import { LogoService, RewardsModalLogo } from '../../../services/Logo.service';

interface logo {
	src: string;
	alt: string;
}

type CmsRewardsFormModalProps = ComponentProps & {
	logo: logo;
	fields: {
		Title: Field<string>;
		'First Description': Field<string>;
		'Second Description': Field<string>;
		'Floating Placeholder': { value: boolean };
	};
};

export const getStaticProps: GetStaticComponentProps = async () => {
	const data = await LogoService.GetLogo<RewardsModalLogo>();

	return { logo: data.body.item.Logo };
};

const CmsRewardsModalRendering = (props: CmsRewardsFormModalProps): JSX.Element => {
	const logo = props?.logo;
	const { sitecoreContext } = useSitecoreContext();
	const isExperienceEditor = !!sitecoreContext.pageEditing;

	return (
		<RewardsModalBlock
			isExperienceEditor={isExperienceEditor}
			pageTitle={sitecoreContext.route?.displayName ?? ''}
			logo={<img src={logo?.src ?? ''} alt={logo?.alt ?? 'Outback Steak House'} />}
			title={props?.fields?.Title?.value ?? ''}
			firstDescription={
				!!props?.fields?.['First Description']?.value ? (
					<RichText field={props?.fields['First Description']} />
				) : undefined
			}
			secondDescription={
				!!props?.fields?.['Second Description']?.value ? (
					<RichText field={props?.fields['Second Description']} />
				) : undefined
			}
		/>
	);
};

export default withDatasourceCheck()<CmsRewardsFormModalProps>(CmsRewardsModalRendering);
