import { Banner, BannerProps } from '@maverick/cms';
import {
	RichText,
	LinkField,
	Text as SitecoreText,
	Field,
	withDatasourceCheck,
	ImageField,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type BannerRenderingProps = ComponentProps & {
	fields: {
		Text?: Field<string>;
		'Footer Text'?: Field<string>;
		Background?: ImageField;
		'Store Badges': Field<boolean>;
		'Ios Link'?: LinkField;
		'Android Link'?: LinkField;
	};
};

const BannerRendering = (props: BannerRenderingProps): JSX.Element => {
	const {
		Text,
		['Footer Text']: FooterText,
		Background,
		['Store Badges']: StoreBadges,
		['Ios Link']: IosLink,
		['Android Link']: AndroidLink,
	} = props.fields;

	const bannerProps: BannerProps = {
		text: !!Text?.value ? <SitecoreText field={Text} /> : undefined,
		footerText: !!FooterText?.value ? <RichText field={FooterText} /> : undefined,
		bgImage: Background?.value?.src ?? '',
		storeBadges: StoreBadges?.value,
		iosLink: IosLink?.value?.href,
		androidLink: AndroidLink?.value?.href,
	};
	return <Banner {...bannerProps} />;
};

export default withDatasourceCheck()<BannerRenderingProps>(BannerRendering);
