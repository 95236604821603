import {
	GetStaticComponentProps,
	withDatasourceCheck,
	Image as SitecoreImage,
	useComponentProps,
	ComponentRendering,
	LayoutServiceData,
	RichText,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { Menu, MenuItemProps, MenuProps } from '@maverick/cms';
import { ComponentProps } from 'lib/component-props';
import { MenuResponse, SitecoreResultsResponse } from '@maverick/entity';
import { MenuService } from 'src/services/Menu.service';

type MenuRenderingProps = ComponentProps;

type SitecoreRawItems = {
	items: MenuResponse[];
};

export const getStaticProps: GetStaticComponentProps = async (
	rendering: ComponentRendering,
	layoutData: LayoutServiceData
) => {
	const result = await MenuService.GetMenu<SitecoreResultsResponse<MenuResponse[]>>(
		rendering?.dataSource ?? '',
		layoutData?.sitecore?.context?.language ?? 'en'
	);

	return { items: result.body.item.children.results };
};

const MenuRendering = (props: MenuRenderingProps): JSX.Element => {
	const data = useComponentProps<SitecoreRawItems>(props.rendering.uid);

	const menu: MenuProps = {
		items: Array<MenuItemProps>(),
	};

	data?.items?.map((item) => {
		let menuItem: MenuItemProps = {
			title: !!item.title?.value ? <RichText field={item.title} /> : undefined,
			subTitle: !!item.subTitle?.value ? <RichText field={item.subTitle} /> : undefined,
			description: !!item.description?.value ? <RichText field={item.description} /> : undefined,
			link: {
				href: item.link?.url,
				target: item.link?.target ?? '_self',
			},
			isNew: item.isNew?.boolValue,
			image: item.image?.src ? <SitecoreImage field={item.image} /> : undefined,
		};
		menu.items!.push(menuItem);
	});

	return <Menu items={menu.items} />;
};

export default withDatasourceCheck()<MenuRenderingProps>(MenuRendering);
