import { useIsOnScreen } from '@maverick/hooks';
import { FC, useRef } from 'react';
import styled, { css } from 'styled-components';

export interface ISteakStoryMeetItem {
	id: string;
	image: string;
	name: string;
	text: string;
	link: string;
}

const Container = styled.li<{ isOnScreen: boolean }>`
	position: absolute;

	@media (min-width: 768.01px) {
		&:nth-child(odd) {
			left: 0;
		}

		&:nth-child(even) {
			right: 0;
		}

		&:nth-child(1) {
			width: 568px;
			height: 263px;
			top: 20px;
		}

		&:nth-child(2) {
			width: 566px;
			height: 297px;
			top: 80px;
		}

		&:nth-child(3) {
			width: 609px;
			height: 336px;
			top: 220px;
		}

		&:nth-child(4) {
			width: 664px;
			height: 352px;
			top: 400px;
		}

		&:nth-child(5) {
			width: 599px;
			height: 347px;
			top: 550px;
		}

		&:nth-child(6) {
			width: 664px;
			height: 382px;
			top: 700px;
		}

		&:nth-child(7) {
			width: 879px;
			height: 467px;
			top: 960px;
		}
	}

	@media (max-width: 768px) {
		position: relative;
		width: 100%;
		height: 400px;
		transition: all ease 0.5s;

		${({ isOnScreen }) => {
			if (isOnScreen) {
				return css`
					filter: blur(0);

					${Name} {
						opacity: 1;
					}
				`;
			}
			return css`
				filter: blur(5px);

				${Name} {
					opacity: 0;
				}
			`;
		}}
	}
`;
const Image = styled.div<{ image: string }>`
	background: url(${({ image }) => image}) center no-repeat;
	background-size: contain;
	height: 100%;

	@media (max-width: 768px) {
		width: calc(100% + 400px);
		margin-left: -200px;
	}
`;
const NameWrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	&:hover {
		${() =>
			css`
				${Name} {
					translate: 0 -40px;
					opacity: 1;
					text-shadow: 0 0 10px #0006;
				}
			`}
	}
`;
const Name = styled.h3`
	margin: 0;
	color: ${({ theme }) => theme.colors.neutral.white};
	font-size: 2rem;
	text-transform: uppercase;
	opacity: 0;
	transition: all ease 0.3s;

	@media (max-width: 768px) {
		font-size: 1.5rem;
		translate: 0 -40px;
	}
`;

interface SteakStoryMeetItemProps extends ISteakStoryMeetItem {
	onClick: () => void;
}
export const SteakStoryMeetItem: FC<SteakStoryMeetItemProps> = ({ image, name, onClick }) => {
	const containerRef = useRef<HTMLLIElement>(null);
	const isOnScreen = useIsOnScreen(containerRef);

	return (
		<Container ref={containerRef} isOnScreen={isOnScreen}>
			<Image image={image} />
			<NameWrapper onClick={onClick}>
				<Name>{name}</Name>
			</NameWrapper>
		</Container>
	);
};
