import { FC } from 'react';
import styled from 'styled-components';
import Link from 'next/link';

const Container = styled.div`
	display: inline-flex;
`;

const Content = styled.div`
	border: 2px solid ${({ theme }) => theme.colors.neutral.white};
	background: rgba(0, 0, 0, 0.3);
	color: ${({ theme }) => theme.colors.neutral.white};
	text-transform: uppercase;
	padding: 16px 40px;
	font-size: 1rem;
	cursor: pointer;
	transition: all ease 0.15s;

	&:hover {
		background: ${({ theme }) => theme.colors.neutral.white};
		color: ${({ theme }) => theme.colors.neutral.black};
	}
`;

interface SteakStoryButtonProps {
	label: string;
	link: string;
}
export const SteakStoryButton: FC<SteakStoryButtonProps> = ({ label, link }) => {
	return (
		<Container role='button'>
			<Link href={link}>
				<a>
					<Content>{label}</Content>
				</a>
			</Link>
		</Container>
	);
};
