import { ComponentRendering, Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { Column } from '@maverick/cms';

const placeholder = ['one'];
const OneColumnRendering = ({ rendering }: { rendering: ComponentRendering }): JSX.Element => {
	return (
		<>
			<Column columns={1}>
				{placeholder.map((p) => (
					<Placeholder key={p} name={`bbi-cms-${p}-column`} rendering={rendering} />
				))}
			</Column>
		</>
	);
};

export default OneColumnRendering;
