import { SectionBanner, SectionProps, asideArrowType } from '@maverick/cms';
import { Field, ImageField, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type SectionBannerRenderingProps = ComponentProps & {
	fields: {
		Title: Field<string>;
		Text: Field<string>;
		Aside: Field<string>;
		Image: ImageField;
		Background: ImageField;
		'Aside Arrow': Field<string>;
		'Background Fixed': Field<boolean>;
		'Dotted Line': Field<boolean>;
	};
};

const SectionBannerRendering = (props: SectionBannerRenderingProps): JSX.Element => {
	const getArrowValue = (value: string) => {
		switch (value) {
			case 'None':
				return asideArrowType.None;
			case 'Up':
				return asideArrowType.Up;
			case 'Down':
				return asideArrowType.Down;
			default:
				return asideArrowType.None;
		}
	};

	const sectionProps: SectionProps = {
		title: props?.fields?.Title?.value,
		text: props?.fields?.Text?.value,
		aside: props?.fields?.Aside?.value,
		image: props?.fields?.Image?.value?.src ?? '',
		bg: props?.fields?.Background?.value?.src ?? '',
		asideArrow: getArrowValue(props?.fields['Aside Arrow']?.value),
		bgFixed: props?.fields['Background Fixed']?.value,
		withDottedLine: props?.fields['Dotted Line']?.value,
	};

	return <SectionBanner {...sectionProps} />;
};

export default withDatasourceCheck()<SectionBannerRenderingProps>(SectionBannerRendering);
