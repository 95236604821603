export const Meet = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		xmlnsXlink='http://www.w3.org/1999/xlink'
		preserveAspectRatio='xMidYMid meet'
		id='meet-svg'
		className='meet-svg'
		x='0px'
		y='0px'
		viewBox='196 326 219 117'
		enableBackground='new 196 326 219 117'
		xmlSpace='preserve'
	>
		<g id='Layer_2'>
			<path
				className='letter-m'
				fill='none'
				stroke='#c7b079'
				strokeWidth='7'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeMiterlimit='10'
				d='M209.8,379.2c0,0,14.2-39.6,23.7-41.1c9.7-1.6-1.2,74.4,0,76.7c1.2,2.4,8.9-60.4,15-59.3c6.7,1.2,6.3,4.7,4.7,52.2c0,0,6.3-38,15.8-38.8c9.5-0.8,2.4,32.4,7.5,37.2'
			></path>
			<path
				className='letter-e'
				fill='none'
				stroke='#c7b079'
				strokeWidth='7'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeMiterlimit='10'
				d='M287.5,407.4c0,0,13.7-10.3,16.3-17.1c2.6-6.9-6.1-9.5-13.2,0.5c-7.6,10.7-3.7,21.1,2.6,21.6c8.2,0.7,11.8-4,19.2-13.7'
			></path>
			<path
				className='letter-a'
				fill='none'
				stroke='#c7b079'
				strokeWidth='7'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeMiterlimit='10'
				d='M340.7,384.7c0,0-19.7,3.5-22.7,23.7c-0.6,4.1,2.4,6.5,6.1,4.6C328.8,410.5,335.5,403.3,340.7,384.7c0,0.1-6.7,23.5-0.2,25.7c2.6,0.9,8-1.5,11.7-8'
			></path>
			<path
				className='letter-t'
				fill='none'
				stroke='#c7b079'
				strokeWidth='7'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeMiterlimit='10'
				d='M367,367.9c0,0-3.8,50.4,7.7,50.4c4.6,0,8.3-5.7,8.3-5.7'
			></path>
			<line
				className='letter-t-cross'
				fill='none'
				stroke='#c7b079'
				strokeWidth='7'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeMiterlimit='10'
				x1='352.1'
				y1='383.9'
				x2='389.7'
				y2='386.6'
			></line>
			<path
				className='comma'
				fill='none'
				stroke='#c7b079'
				strokeWidth='7'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeMiterlimit='10'
				d='M400.4,411.8c0,0-1.6,8.9-9.9,15.4'
			></path>
		</g>
	</svg>
);

export const MeetDash = () => (
	<svg
		className='meet-svg'
		x='0px'
		y='0px'
		viewBox='167 383 280 25'
		enableBackground='new 167 383 280 25'
		xmlSpace='preserve'
		xmlns='http://www.w3.org/2000/svg'
		xmlnsXlink='http://www.w3.org/1999/xlink'
		preserveAspectRatio='xMidYMid meet'
	>
		<line
			className='meet-dash'
			fill='none'
			stroke='#c7b079'
			strokeWidth='10'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeMiterlimit='10'
			x1='173.6'
			y1='392.3'
			x2='438.4'
			y2='399.7'
		></line>
	</svg>
);

export const NumCircle = () => (
	<svg
		className='num-circle'
		x='0px'
		y='0px'
		viewBox='129 410 40 39'
		enableBackground='new 129 410 40 39'
		xmlSpace='preserve'
	>
		<path
			className='num-path'
			fill='none'
			stroke='#fff'
			strokeWidth='2'
			strokeLinecap='round'
			strokeMiterlimit='10'
			d='M141,422.9 c0,0,11.9-6.4,16.2-5.6s9.2,4.6,4.5,15.6c-4.6,10.9-14.4,14.4-21.2,12.3c-6.8-2.1-8.3-13.3-5-18.8c0,0,3.8-8.9,11.2-8.9 c5.6,0,5.9,4.4,5.9,4.4'
		></path>
	</svg>
);
