import { ConvertToDate } from '@maverick/entity';
import { format } from 'date-fns';
import { FC } from 'react';
import { Article } from 'src/services/BlogList.service';
import * as Styled from './BlogList.styled';

interface BlogListProps {
	items: Array<Article>;
}

const formatDate = (timePlaced: string) => {
	const convertedDate = ConvertToDate(timePlaced);

	return format(convertedDate, 'MMM dd, yyyy');
};

export const BlogList: FC<BlogListProps> = ({ items }) => {
	return (
		<Styled.Container data-testid='blogList'>
			{items?.map((x) => {
				return (
					<Styled.Item key={x.url}>
						<Styled.Date>{formatDate(x.date)}</Styled.Date>
						<Styled.Link href={x.url}>{x.title}</Styled.Link>
					</Styled.Item>
				);
			})}
		</Styled.Container>
	);
};
