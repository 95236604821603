import { forwardRef } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { NumCircle } from '../assets';

const writeSvg = keyframes`
    from {stroke-dashoffset: 500;}
    to {stroke-dashoffset: 0;}
`;

const Container = styled.div<{ isOnScreen: boolean }>`
	display: flex;
	align-items: center;
	margin: 0 20% 0;
	min-height: 472px;
	opacity: 0;
	transition: all ease 0.25s;

	${({ isOnScreen }) =>
		isOnScreen &&
		css`
			opacity: 1;

			.num-path {
				animation: ${writeSvg} 1.5s ease-in-out forwards;
			}
		`}

	@media(max-width: 768px) {
		margin: 64px 0 0;
		padding: 0 32px;
		align-items: flex-start;
		min-height: unset;
	}
`;
const Num = styled.div<{ num: string }>`
	width: 70px;
	margin: 0 24px 0 0;
	position: relative;

	.num-path {
		stroke-dasharray: 500;
		stroke-dashoffset: 500;
	}

	&:after {
		content: '${({ num }) => num}';
		position: absolute;
		top: 20px;
		left: 28px;
		font-family: 'jenna-sue';
		font-size: 2.8rem;
	}
`;
const Text = styled.p`
	flex: 1;
	font-size: 2rem;

	@media (max-width: 768px) {
		margin: 0;
		font-size: 1.6rem;
	}
`;

interface SteakStoryTextNumProps {
	num: string;
	text: JSX.Element;
	isOnScreen: boolean;
}
export const SteakStoryNumText = forwardRef<HTMLDivElement, SteakStoryTextNumProps>(
	({ num, text, isOnScreen }, ref) => {
		return (
			<Container ref={ref} isOnScreen={isOnScreen}>
				<Num num={num}>
					<NumCircle />
				</Num>
				<Text>{text}</Text>
			</Container>
		);
	}
);
