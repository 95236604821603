import { GraphQLResponse } from '@maverick/http';
import { SitecoreClient } from './graphql/GraphQL.service';
import { GetCatalogItens } from './graphql/Queries/Catalog.graphql';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';

export interface CatalogResponse {
	item: {
		children: {
			results: SitecoreCatalogRawProps[];
		};
	};
}
export interface SitecoreCatalogRawProps {
	title: Field<string>;
	subtitle: Field<string>;
	description: Field<string>;
	note: Field<string>;
	firstButton: {
		text: string;
		url: string;
		target: string;
	};
	secondButton: {
		text: string;
		url: string;
		target: string;
	};
	image: { src: string; alt: string };
	variant: Field<string>;
	verticalView: Field<boolean>;
	buttonVariant: Field<string>;
}
export interface SitecoreCatalogRawChildren {
	catalogItens: SitecoreCatalogRawProps[];
}

export const CatalogService = {
	GetCatalogItens: async <T>(datasource: string, language: string): Promise<GraphQLResponse<T>> => {
		return SitecoreClient.ExecuteRequest<T>(GetCatalogItens, {
			datasource: datasource,
			language: language,
		});
	},
};
