import { Text, RichText, DateField, Field, withSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { ArticlePage } from '@maverick/cms';
import { ComponentProps } from 'lib/component-props';
import { format } from 'date-fns';

export type ArticlePageRenderingProps = ComponentProps & {
	sitecoreContext: {
		route: {
			fields: {
				Date: Field<string>;
				Title: Field<string>;
				Subtitle: Field<string>;
				Article: Field<string>;
			};
		};
	};
};

export const ArticlePageRendering = (props: ArticlePageRenderingProps): JSX.Element => {
	const {Date, Title, Subtitle, Article} = props?.sitecoreContext?.route?.fields;
	return (
		<ArticlePage
			date={ <DateField  field={Date} render={(date) => format(date ?? 0, 'MMM dd, yyyy')} />}
			title={<Text field={Title} />}
			subtitle={<RichText field={Subtitle} />}
			article={<RichText field={Article} />}
		/>
	);
};


export default withSitecoreContext()<any>(ArticlePageRendering);
