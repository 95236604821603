import { LinkFieldValue } from '@sitecore-jss/sitecore-jss-nextjs';

export interface SlideResponse {
	id: string;
	name: string;
	image: { src: string; alt: string };
	link: { jsonValue: { value: LinkFieldValue } };
}

export interface CarouselResponse {
	item: {
		children: {
			results: SlideResponse[];
		};
	};
}
