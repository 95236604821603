import { FC, useEffect } from 'react';

export const GoToMenu: FC = () => {
	useEffect(() => {
		let url = `${String(process.env.CMS_URL)}/menu`;
		window.location.replace(url);
	}, []);

	return null;
};
