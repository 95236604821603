import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div<{ isOnScreen: boolean }>`
	margin: 0 auto;
	text-align: center;
	opacity: 0;
	transition: all ease 0.25s;

	h2 {
		text-transform: uppercase;
		font-size: 6rem;
		margin: 0;
	}

	p {
		font-family: 'jenna-sue';
		font-size: 3.5rem;
		margin: 0;
	}

	${({ isOnScreen }) =>
		isOnScreen &&
		css`
			opacity: 1;
		`}

	@media(max-width: 768px) {
		padding: 0 32px;

		h2 {
			font-size: 3.4rem;
		}

		p {
			margin: 32px 0 0;
			font-size: 2.4rem;
		}
	}
`;

interface SteakStoryTitleProps {
	title: JSX.Element;
	subtitle?: JSX.Element;
	isOnScreen: boolean;
}
export const SteakStoryTitle = forwardRef<HTMLDivElement, SteakStoryTitleProps>(
	({ title, subtitle, isOnScreen }, ref) => {
		return (
			<Container isOnScreen={isOnScreen} ref={ref}>
				<h2>{title}</h2>
				{!!subtitle && <p>{subtitle}</p>}
			</Container>
		);
	}
);

SteakStoryTitle.displayName = 'SteakStoryTitle';
