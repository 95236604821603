import { FC, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

const Holder = styled.div<Pick<NutritionHolder, 'staticPosition'>>`
	max-width: 100%;
	margin: 0 auto;
	padding: 0 16px;
	position: relative;

	${({ staticPosition }) =>
		staticPosition &&
		css`
			position: static;
			overflow: visible;
		`}
`;

export interface NutritionHolder {
	staticPosition?: boolean;
}
export const NutritionHolder: FC<PropsWithChildren<NutritionHolder>> = ({ children, staticPosition = false }) => (
	<Holder staticPosition={staticPosition}>{children}</Holder>
);
