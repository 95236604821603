import { InternationalLocation } from '@maverick/entity';
import { useInput } from '@maverick/hooks';
import { DropdownItem, Holder, Icon } from '@maverick/ui';
import { IconMapPin } from '@maverick/icons/dist/IconMapPin';
import { FC, useEffect, useState, useRef } from 'react';

import * as Styled from './International.styled';
import { CmsDropdown, DottedLine, ImgType, LocationManager } from '@maverick/cms';

export const International: FC = () => {
	const [countries, setCountries] = useState<Array<DropdownItem>>();
	const country = useInput('', { disabled: true });
	const [locations, setLocations] = useState<Array<InternationalLocation>>();
	const heroRef = useRef<HTMLDivElement>(null);
	const contentRef = useRef<HTMLDivElement>(null);
	const [remainingSpaceHeight, setRemainingSpaceHeight] = useState<number>(0);

	useEffect(() => {
		listCountries();
	}, []);

	useEffect(() => {
		handleRemainingSpaceHeight();
		window.addEventListener('resize', handleRemainingSpaceHeight);

		return () => {
			window.removeEventListener('resize', handleRemainingSpaceHeight);
		};
	}, []);

	useEffect(() => {
		handleCountrySelected();
	}, [country.value]);

	const listCountries = async () => {
		const result = await LocationManager.ListCountries();
		if (!result) {
			return;
		}
		setCountries([
			{ value: '', text: 'Country/territory', disabled: true },
			...result.map((i) => ({ text: i, value: i })),
		]);
		country.setDisabled(false);
	};

	const handleCountrySelected = async () => {
		const result = await LocationManager.ListCountryLocations(country.value);
		if (!result) {
			return;
		}

		setLocations(result);
		scrollContentToTop();
	};

	const scrollContentToTop = () => {
		const header = document.querySelector('#header');
		if (!header || !contentRef.current) return;

		const offset = header.getBoundingClientRect().bottom;
		const y = contentRef.current?.getBoundingClientRect().top + window.pageYOffset - offset;

		window.scrollTo({
			top: y,
			behavior: 'smooth',
		});
	};

	const handleRemainingSpaceHeight = () => {
		const header = document.querySelector('#header');
		const footer = document.querySelector('#footer');

		if (header && footer && heroRef.current) {
			const windowHeight = window.innerHeight;
			const headerHeight = header.getBoundingClientRect().bottom;
			const heroHeight = heroRef.current.getBoundingClientRect().height;
			const footerHeight = footer.getBoundingClientRect().height;

			const height = windowHeight - headerHeight - heroHeight - footerHeight;
			if (height > 0) setRemainingSpaceHeight(height);
		}
	};

	return (
		<Styled.Container>
			<Styled.Hero ref={heroRef}>
				<Styled.HeroContent>
					<h1>Location directory</h1>
					<p>Select a Country/Territory below</p>
					<Styled.Dropdown>
						<CmsDropdown handler={country} items={countries?.length ? countries : []} />
					</Styled.Dropdown>
				</Styled.HeroContent>
			</Styled.Hero>

			{!!country.value && !!locations?.length ? (
				<Styled.Content ref={contentRef}>
					<DottedLine img={ImgType.dottedLineWithBorder} onlyMobile={false} />
					<Styled.List>
						{locations.map((l) => {
							const addressLink = `${l.name} ${l.address}, ${l.city}, ${l.state}, ${l.zip}`.replaceAll(
								' ',
								'+'
							);
							return (
								<Styled.Item key={l.unitId}>
									<Holder>
										<Styled.ItemContent>
											<Styled.ItemIcon>
												<Icon icon={IconMapPin} customSize />
											</Styled.ItemIcon>
											<Styled.ItemMain>
												<h5>{l.name}</h5>
												<Styled.SectionTitle>Address</Styled.SectionTitle>
												<Styled.SectionContent>
													<a href={`https://google.com/maps?daddr=${addressLink}`}>
														{l.address}
														<br />
														{l.city}, {l.state} {l.zip}
													</a>
													<br />
													<span>{l.phone}</span>
												</Styled.SectionContent>
											</Styled.ItemMain>
										</Styled.ItemContent>
									</Holder>
								</Styled.Item>
							);
						})}
					</Styled.List>
				</Styled.Content>
			) : (
				<Styled.RemainingSpace height={remainingSpaceHeight} />
			)}
		</Styled.Container>
	);
};
