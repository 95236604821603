import { useIsMobile } from '@maverick/hooks';
import { FC, useRef, RefObject, useEffect } from 'react';
import styled from 'styled-components';

const CanvasWrapper = styled.div`
	position: sticky;
	z-index: 2;
	width: 100%;
	height: 100vh;
	top: 0;
	bottom: 0;
`;
const Canvas = styled.canvas`
	width: 100%;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`;

interface CanvasProps {
	containerRef: RefObject<HTMLDivElement>;
	frameCount: number;
	framesBasePath: string;
}
export const SteakStoryCanvas: FC<CanvasProps> = ({ containerRef, frameCount, framesBasePath }) => {
	const isMobile = useIsMobile(768);
	const canvasWrapperRef = useRef<HTMLDivElement>(null);
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const contextRef = useRef<CanvasRenderingContext2D | null>(null);
	const imgRef = useRef<HTMLImageElement>();
	const hackyResized = useRef<boolean>(false);

	useEffect(() => {
		if (!containerRef.current || !canvasRef.current || isMobile) return;

		contextRef.current = canvasRef.current.getContext('2d');
		imgRef.current = new Image();

		const onScroll = () => {
			if (!containerRef.current) return;

			const html = document.documentElement;
			const scrolled = html.scrollTop;
			const containerTop = containerRef.current.offsetTop;
			const containerHeight = containerRef.current.clientHeight;
			const screenHeight = window.innerHeight;
			const start = scrolled + screenHeight - containerTop;

			if (start < 0) return;

			if (!hackyResized.current) {
				window.dispatchEvent(new Event('resize'));
				hackyResized.current = true;
			}

			const maxScrollTop = containerHeight;
			const scrollFraction = start / maxScrollTop;
			const frameIndex = Math.min(frameCount - 1, Math.ceil(scrollFraction * frameCount));

			requestAnimationFrame(() => updateImage(frameIndex + 1));
		};

		window.addEventListener('scroll', onScroll);
		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	}, [isMobile]);

	useEffect(() => {
		const onResize = () => {
			if (canvasRef.current && canvasWrapperRef.current) {
				canvasRef.current.width = canvasWrapperRef.current.clientWidth;
				canvasRef.current.height = canvasWrapperRef.current.clientHeight;

				canvasRef.current.style.backgroundImage = `url${currentFrame(1)}`;
			}
		};

		onResize();
		window.addEventListener('resize', onResize);
		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, []);

	const updateImage = (index: number) => {
		imgRef.current!.src = currentFrame(index);
		imgRef.current!.onload = () => {
			if (!canvasRef.current) return;
			const canvasWidth = canvasRef.current.clientWidth;
			const canvasHeight = canvasRef.current.clientHeight;

			const imgWidth = imgRef.current!.width;
			const imgHeight = imgRef.current!.height;

			const scale = Math.max(canvasWidth / imgWidth, canvasHeight / imgHeight);
			const newWidth = imgWidth * scale;
			const newHeight = imgHeight * scale;
			const x = canvasWidth / 2 - newWidth / 2;
			const y = canvasHeight / 2 - newHeight / 2;

			contextRef.current?.drawImage(imgRef.current!, x, y, newWidth, newHeight);
		};
	};

	const currentFrame = (index: number) => `${framesBasePath}${String(index).padStart(3, '0')}.webp`;

	if (isMobile) return null;

	return (
		<CanvasWrapper ref={canvasWrapperRef}>
			<Canvas ref={canvasRef} width='100%' height='100%' />
		</CanvasWrapper>
	);
};
