import { VerticalSpace, VerticalSpaceProps } from '@maverick/cms';
import { ComponentProps } from 'lib/component-props';

type VerticalSpaceRenderingProps = ComponentProps & {
	params: {
		Padding: string;
	};
};

const VerticalSpaceRendering = (props: VerticalSpaceRenderingProps): JSX.Element => {
	const verticalSpaceProps: VerticalSpaceProps = {
		Padding: props?.params?.Padding,
	};

	return <VerticalSpace {...verticalSpaceProps} />;
};

export default VerticalSpaceRendering;
