import {
	GetStaticComponentProps,
	withDatasourceCheck,
	useComponentProps,
	ComponentRendering,
	LayoutServiceData,
	useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { TabNavigation, TabNavigationItemProps, TabNavigationProps } from '@maverick/cms';
import Link from 'next/link';
import { TabNavigationService } from 'src/services/TabNavigation.service';
import { SitecoreResultsResponse, TabNavigationResponse } from '@maverick/entity';

type TabNavigationRenderingProps = ComponentProps & SitecoreResultsResponse<TabNavigationResponse>;

type SitecoreRawItems = {
	items: TabNavigationResponse[];
};

export const getStaticProps: GetStaticComponentProps = async (
	rendering: ComponentRendering,
	layoutData: LayoutServiceData
) => {
	const result = await TabNavigationService.GetTabNavigation<SitecoreResultsResponse<TabNavigationResponse>>(
		rendering?.dataSource ?? '',
		layoutData?.sitecore?.context?.language ?? 'en'
	);

	return { items: result.body.item.children.results };
};

const TabNavigationRendering = (props: TabNavigationRenderingProps): JSX.Element => {
	const data = useComponentProps<SitecoreRawItems>(props.rendering.uid);
	const context = useSitecoreContext();

	const tabNavigation: TabNavigationProps = {
		items: Array<TabNavigationItemProps>(),
		currentPage: context.sitecoreContext.itemPath as string,
	};

	data?.items?.map((item) => {
		let tabItem: TabNavigationItemProps = {
			path: item?.url?.path,
			text: item?.displayName,
			isActive: false,
			Link: Link,
		};
		tabNavigation.items!.push(tabItem);
	});

	return <TabNavigation items={tabNavigation.items} currentPage={tabNavigation.currentPage} />;
};

export default withDatasourceCheck()<TabNavigationRenderingProps>(TabNavigationRendering);
