import {
	GetStaticComponentProps,
	RichText,
	withDatasourceCheck,
	RichTextField,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { Grid, GridVariants, GridProps, JumbotronProps, Jumbotron, TextAlign } from '@maverick/cms';
import { ComponentProps } from 'lib/component-props';
import { GetJumbotronsList } from 'src/services/Jumbotron.service';
import { JumbotronListResponse, JumbotronResponse, SitecoreVariantRenderingParamenterResponse } from '@maverick/entity';

type JumbotronListRenderingProps = ComponentProps & JumbotronListResponse & SitecoreVariantRenderingParamenterResponse;

export const getStaticProps: GetStaticComponentProps = async (rendering) => {
	return await GetJumbotronsList(rendering.dataSource!);
};

const JumbotronListRendering = (props: JumbotronListRenderingProps): JSX.Element => {
	const gridProps: GridProps = {
		variant: GridVariants.ONE,
	};

	const handleContentAlignment = (contentAlignment: string) => {
		const align = contentAlignment ?? TextAlign.Center;

		if (align === TextAlign.Left) return 'left';

		if (align === TextAlign.Right) return 'right';

		return 'center';
	};

	const getJumbotronProps = (jumbotron: JumbotronResponse) => {
		// TODO - The solution using 'service/notfound.aspx' is temporary. Waiting sitecore ticket to finish to resolve this issue.
		const jumbotronProps: JumbotronProps = {
			title: !!jumbotron?.title?.value ? (
				!!jumbotron?.title?.value.includes('service/notfound.aspx') ? (
					<RichText field={jumbotron?.title?.jsonValue as RichTextField} />
				) : (
					<RichText field={jumbotron?.title as RichTextField} />
				)
			) : undefined,
			text: !!jumbotron?.text?.value ? (
				!!jumbotron?.text?.value.includes('service/notfound.aspx') ? (
					<RichText field={jumbotron?.text?.jsonValue as RichTextField} />
				) : (
					<RichText field={jumbotron?.text as RichTextField} />
				)
			) : undefined,
			button1: {
				href: jumbotron.firstButton?.url ?? '/',
				text: jumbotron.firstButton?.text ?? '',
				target: jumbotron.firstButton?.target ?? '',
			},
			button2: {
				href: jumbotron.secondButton?.url ?? '/',
				text: jumbotron.secondButton?.text ?? '',
				target: jumbotron.secondButton?.target ?? '',
			},
			firstNote: !!jumbotron?.firstNote?.value ? (
				!!jumbotron?.firstNote?.value.includes('service/notfound.aspx') ? (
					<RichText field={jumbotron?.firstNote?.jsonValue as RichTextField} />
				) : (
					<RichText field={jumbotron?.firstNote as RichTextField} />
				)
			) : undefined,
			secondNote: !!jumbotron?.secondNote?.value ? (
				!!jumbotron?.secondNote?.value.includes('service/notfound.aspx') ? (
					<RichText field={jumbotron?.secondNote?.jsonValue as RichTextField} />
				) : (
					<RichText field={jumbotron?.secondNote as RichTextField} />
				)
			) : undefined,
			isJumbotronList: true,
			contentAlignment: handleContentAlignment(jumbotron.contentAlignment.value),
		};
		return <Jumbotron {...jumbotronProps} isJumbotronList />;
	};

	return <Grid {...gridProps}>{props.results.map((jumbotron) => getJumbotronProps(jumbotron))}</Grid>;
};

export default withDatasourceCheck()<JumbotronListRenderingProps>(JumbotronListRendering);
