import { Icon } from '@maverick/ui';
import { IconClose } from '@maverick/icons/dist/IconClose';
import { IconChevronRight } from '@maverick/icons/dist/IconChevronRight';
import { IconChevronLeft } from '@maverick/icons/dist/IconChevronLeft';
import { FC, useEffect } from 'react';
import { ISteakStoryMeetItem } from './SteakStoryMeetItem';
import styled, { keyframes } from 'styled-components';

const enter = keyframes`
    from {opacity: 0}
    to {opacity: 1}
`;

const Backdrop = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	background: #0009;
	top: 0;
	left: 0;
	z-index: 9;
	animation: ${enter} 0.25s forwards;
`;

const Container = styled.div`
	position: fixed;
	z-index: 10;
	width: calc(100% - 64px);
	background: url(https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/static-pages/bg-texture.png)
		#262524;
	top: 132px;
	left: 32px;
	padding: 24px 24px 100px;
	color: ${({ theme }) => theme.colors.neutral.white};
	animation: ${enter} 0.5s forwards;
	max-height: calc(100% - 132px - 32px);
	overflow-y: auto;

	@media (max-width: 768px) {
		top: calc(62px + 16px);
		left: 16px;
		right: 16px;
		width: calc(100% - 32px);
	}
`;
const Top = styled.div`
	margin: 32px 0 0;
	text-align: center;

	h4 {
		text-transform: uppercase;
		font-size: 3rem;
		margin: 0;
	}

	@media (max-width: 768px) {
		h4 {
			font-size: 1.8rem;
			min-height: 70px;
		}
	}
`;
const Close = styled.div`
	position: absolute;
	width: 40px;
	height: 40px;
	top: 24px;
	right: 24px;
	transition: all ease 0.4s;
	cursor: pointer;

	&:hover {
		rotate: 90deg;
	}

	@media (max-width: 768px) {
		top: 8px;
		right: 8px;
	}
`;
const Content = styled.div`
	margin: 32px 0 0;
	display: flex;

	@media (max-width: 768px) {
		align-items: flex-start;
	}
`;
const Chevron = styled.div`
	width: 100px;
	opacity: 0.5;
	transition: all ease 0.4s;
	cursor: pointer;
	padding: 70px 0 0;

	&:hover {
		opacity: 1;
	}

	g {
		stroke-width: 1;
	}

	@media (max-width: 768px) {
		width: 40px;
	}
`;
const Main = styled.div`
	flex: 1;
`;
const Image = styled.div`
	height: 400px;
	max-height: 40vh;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;

	@media (max-width: 768px) {
		height: 200px;
		max-height: 20vh;
	}
`;
const MainBottom = styled.div`
	p {
		font-size: 1.4rem;
		max-width: 600px;
		text-align: center;
		margin: 0 auto;
	}

	@media (max-width: 768px) {
		p {
			font-size: 1rem;
		}
	}
`;

interface SteakStoryModalProps {
	item: ISteakStoryMeetItem;
	onPrev: () => void;
	onNext: () => void;
	onClose: () => void;
}
export const SteakStoryModal: FC<SteakStoryModalProps> = ({ item, onPrev, onNext, onClose }) => {
	useEffect(() => {
		const onKeyDown = (ev: KeyboardEvent) => {
			if (ev.key === 'ArrowRight') {
				onNext();
			} else if (ev.key === 'ArrowLeft') {
				onPrev();
			} else if (ev.key === 'Escape') {
				onClose();
			}
		};

		window.addEventListener('keydown', onKeyDown);
		return () => {
			window.removeEventListener('keydown', onKeyDown);
		};
	}, [item]);

	const { name, image, text } = item;
	return (
		<>
			<Backdrop onClick={onClose} />
			<Container onClick={(ev) => ev.stopPropagation()}>
				<Top>
					<h4>{name}</h4>
				</Top>
				<Close role='button' onClick={onClose}>
					<Icon icon={IconClose} ariaLabel='close' customSize />
				</Close>
				<Content>
					<Chevron role='button' onClick={onPrev}>
						<Icon icon={IconChevronLeft} customSize />
					</Chevron>
					<Main>
						<Image style={{ backgroundImage: `url(${image})` }} />
						<MainBottom>
							<p>{text}</p>
						</MainBottom>
					</Main>
					<Chevron role='button' onClick={onNext}>
						<Icon icon={IconChevronRight} customSize />
					</Chevron>
				</Content>
			</Container>
		</>
	);
};
