import { FC, useState, useEffect, useRef, RefObject } from 'react';
import { SteakStoryCook } from './sections/SteakStory.Cook';
import { SteakStoryDoneRight } from './sections/SteakStory.DoneRight';
import { SteakStoryGreatness } from './sections/SteakStory.Greatness';
import { SteakStoryHero } from './sections/SteakStory.Hero';
import { SteakStoryMeet } from './sections/SteakStory.Meet';
import { SteakStoryPerfection } from './sections/SteakStory.Perfection';
import { SteakStorySeason } from './sections/SteakStory.Season';
import { useIsMobile } from '@maverick/hooks';

import * as Styled from './SteakStory.styled';

const meetImages = [
	'meet-top',
	'meet-bottom',
	'steak-signature-sirloin',
	'steak-ribeye',
	'steak-ny-strip',
	'steak-bone-in-ribeye',
	'steak-filet',
	'steak-porterhouse',
	'steak-prime-rib',
].map((i) => `https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Steak-Story/steak-meet/${i}.webp`);

const perfectionImages = [...Array(142)].map(
	(_, i) =>
		`https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Steak-Story/steak-perfection/steak-perfection-${String(
			i + 1
		).padStart(3, '0')}.webp`
);

const greatnessImages = [
	'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/static-pages/bg-texture.png',
	'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/shared/border-small-top-red.png',
	'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Horizontal-Line/section-border-top-beige.webp',
	'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Steak-Story/steak-greatness/greatness-woodfire.webp',
	'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Steak-Story/steak-greatness/greatness-seasoning.webp',
];

const seasonImages = [...Array(98)].map(
	(_, i) =>
		`https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Steak-Story/steak-season/steak-season-${String(
			i + 1
		).padStart(3, '0')}.webp`
);

const cookImages = [...Array(35)].map(
	(_, i) =>
		`https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Steak-Story/steak-cook/steak-cook-${
			i + 1
		}.webp`
);

const preload = async (images: Array<string>) => {
	const imgs = images.map((image) => {
		const img = new Image();
		img.src = image;
		return img;
	});

	await Promise.all(
		imgs.map((i) => {
			return new Promise<void>((resolve) => {
				i.onload = () => resolve();
				i.onerror = () => resolve();
				i.onabort = () => resolve();
			});
		})
	);
};

const scrollIntoView = (ref: RefObject<HTMLDivElement>) => {
	if (!ref.current) return;

	const top = ref.current.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 62;
	window.scrollTo({
		behavior: 'smooth',
		top,
	});
};

export const SteakStory: FC = () => {
	const isMobile = useIsMobile(768);
	const [total, setTotal] = useState(1);
	const [loaded, setLoaded] = useState<number>(0);

	const sectionMeet = useRef<HTMLDivElement>(null);
	const sectionSeason = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const footer = document.querySelector<HTMLElement>('.container:not(.container-main)');
		if (!footer) return;

		footer.style.display = 'none';
		return () => {
			footer.style.display = 'initial';
		};
	}, []);

	useEffect(() => {
		setTotal(isMobile ? 3 : 5);

		const preloadImages = () => {
			preload(meetImages).then(() => setLoaded((prev) => prev + 1));
			preload(greatnessImages).then(() => setLoaded((prev) => prev + 1));
			preload(cookImages).then(() => setLoaded((prev) => prev + 1));

			if (!isMobile) {
				preload(perfectionImages).then(() => setLoaded((prev) => prev + 1));
				preload(seasonImages).then(() => setLoaded((prev) => prev + 1));
			}
		};

		preloadImages();
	}, [isMobile]);

	const progress = loaded / total;

	return (
		<Styled.Container>
			{progress < 1 ? (
				<Styled.Loading progress={progress} />
			) : (
				<>
					<SteakStoryHero onScrollCtaClick={() => scrollIntoView(sectionMeet)} />
					<SteakStoryMeet ref={sectionMeet} />
					<SteakStoryPerfection />
					<SteakStoryGreatness onScrollCtaClick={() => scrollIntoView(sectionSeason)} />
					<SteakStorySeason ref={sectionSeason} />
					<SteakStoryCook />
					<SteakStoryDoneRight />
				</>
			)}
		</Styled.Container>
	);
};
