import { Holder } from '@maverick/ui';
import { ComponentRendering, Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { SideBarContainer, SideBarContent, SideBarMain, SideBarNav, SideBarTitle } from '@maverick/cms';

const SideBarContainerRendering = ({ rendering }: { rendering: ComponentRendering }): JSX.Element => {
	return (
		<>
			<SideBarContainer>
				<Holder>
					<SideBarTitle>
						{<Placeholder name='bbi-cms-side-bar-container-title' rendering={rendering} />}
					</SideBarTitle>
					<SideBarContent>
						<SideBarNav>
							{<Placeholder name='bbi-cms-side-bar-container-navigation' rendering={rendering} />}
						</SideBarNav>
						<SideBarMain>
							{<Placeholder name='bbi-cms-side-bar-container-content' rendering={rendering} />}
						</SideBarMain>
					</SideBarContent>
				</Holder>
			</SideBarContainer>
		</>
	);
};

export default SideBarContainerRendering;
