import { withDatasourceCheck, LinkField, Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { BodyMessage } from '@maverick/cms';

type bodyMessageRenderingProps = ComponentProps & {
	fields: {
		Link: LinkField;
		'Message Mobile': Field<string>;
	};
};

const BodyMessageRendering = (props: bodyMessageRenderingProps): JSX.Element => {
	let linkValue = props?.fields?.Link?.value?.href;

	if (props?.fields?.Link?.value?.querystring)
		linkValue = props?.fields?.Link?.value?.href?.concat('?', props?.fields?.Link?.value?.querystring ?? '');

	return (
		<BodyMessage
			link={{
				href: linkValue ?? '',
				text: props?.fields?.Link?.value?.text ?? '',
				target: props?.fields?.Link?.value?.target ?? '_blank',
			}}
			messageMobile={
				props?.fields?.['Message Mobile'].value === ''
					? props?.fields?.Link?.value?.text ?? ''
					: props?.fields?.['Message Mobile'].value
			}
		/>
	);
};

export default withDatasourceCheck()<bodyMessageRenderingProps>(BodyMessageRendering);
