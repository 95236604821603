import { useRef, useState, forwardRef } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Meet, MeetDash } from './assets';
import { useIsOnScreen } from '@maverick/hooks';
import { SteakStoryModal } from './components';
import { ISteakStoryMeetItem, SteakStoryMeetItem } from './components/SteakStoryMeetItem';

const items: Array<ISteakStoryMeetItem> = [
	{
		id: 'signature-sirloin',
		image: 'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Steak-Story/steak-meet/steak-signature-sirloin.webp',
		name: 'Signature Sirloin',
		text: 'One of the leanest cuts, hearty and full of flavor. Our chefs recommend ordering this cut in the Seasoned & Seared preparation: seasoned with our special blend of spices and seared on a hot grill to lock in the juicy flavor.',
		link: 'https://qa2.outback.com/menu/steaks/seasoned-seared-classic-cuts/outback-sirloin',
	},
	{
		id: 'ribeye',
		image: 'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Steak-Story/steak-meet/steak-ribeye.webp',
		name: 'Ribeye',
		text: 'Well-marbled, juicy and savory. Our chefs recommend ordering this cut in the Char-Grilled preparation: seasoned and grilled for a delicious natural flavor.',
		link: 'https://qa2.outback.com/menu/steaks/wood-fire-grilled-specialty-cuts/ribeye',
	},
	{
		id: 'ny-strip',
		image: 'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Steak-Story/steak-meet/steak-ny-strip.webp',
		name: 'New York Strip',
		text: 'Full of rich flavor. Our chefs recommend ordering this cut in the Seasoned & Seared preparation: seasoned with our special blend of herbs & spices and seared on a hot grill to lock in the juicy flavor.',
		link: 'https://qa2.outback.com/menu/steaks/seasoned-seared-classic-cuts/new-york-strip',
	},
	{
		id: 'bone-in-ribeye',
		image: 'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Steak-Story/steak-meet/steak-bone-in-ribeye.webp',
		name: 'Bone-in Ribeye',
		text: 'Juicy, bone-in and extra marbled for maximum tenderness. Our chefs recommend ordering these cuts in the Char-Grilled preparation: seasoned and grilled for a delicious natural flavor.',
		link: 'https://qa2.outback.com/menu/steaks/wood-fire-grilled-specialty-cuts/bone-in-natural-cut-ribeye',
	},
	{
		id: 'filet',
		image: 'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Steak-Story/steak-meet/steak-filet.webp',
		name: "Victoria's Filet® Mignon",
		text: 'A tender and juicy thick barrel cut filet. Our chefs recommend ordering this cut in the Seasoned & Seared preparation: seasoned with our special blend of herbs & spices and seared on a hot grill to lock in the juicy flavor.',
		link: 'https://qa2.outback.com/menu/steaks/seasoned-seared-classic-cuts/victorias-filet-mignon',
	},
	{
		id: 'porterhouse',
		image: 'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Steak-Story/steak-meet/steak-porterhouse.webp',
		name: 'Porterhouse',
		text: 'A flavorful strip and filet tenderloin together. Our chefs recommend ordering this cut in the Seasoned & Seared preparation: seasoned with our special blend of herbs & spices and seared on a hot grill to lock in the juicy flavor.',
		link: 'https://qa2.outback.com/menu/steaks/seasoned-seared-classic-cuts/22-oz-porterhouse',
	},
	{
		id: 'prime-rib',
		image: 'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Steak-Story/steak-meet/steak-prime-rib.webp',
		name: 'Slow-roasted Prime Rib',
		text: 'Seasoned with an herb crust, served with au jus and hand-carved to order.',
		link: 'https://qa2.outback.com/menu/steaks/wood-fire-grilled-specialty-cuts/slow-roasted-prime-rib',
	},
];

const writeSvg = keyframes`
    from {stroke-dashoffset: 500;}
    to {stroke-dashoffset: 0;}
`;

const Container = styled.section`
	margin: 100vh 0 0;
	position: relative;
	z-index: 2;
`;
const Top = styled.div`
	background: url(https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Steak-Story/steak-meet/meet-top.webp)
		center no-repeat;
	background-size: cover;
	padding: 150px 0 100px;
	text-align: center;
	color: ${({ theme }) => theme.colors.neutral.white};

	h2 {
		margin: 0;
		font-size: 6rem;
		text-transform: uppercase;

		span {
			position: relative;
		}
	}

	p {
		margin: 24px auto 0;
		max-width: 600px;
		font-size: 1.5rem;
	}

	@media (max-width: 768px) {
		h2 {
			font-size: 3rem;
			padding: 0 20%;
		}

		p {
			font-size: 1.2rem;
			padding: 0 32px;
		}
	}
`;
const MeetWrapper = styled.div<{ isOnScreen: boolean }>`
	width: 169px;
	height: 90px;
	position: absolute;
	left: 0;
	bottom: 100%;

	.letter-m,
	.letter-e,
	.letter-a,
	.letter-t,
	.letter-t-cross,
	.comma {
		stroke-dasharray: 500;
		stroke-dashoffset: 500;
	}

	${({ isOnScreen }) =>
		isOnScreen &&
		css`
			.letter-m {
				animation: ${writeSvg} 1s 0.25s ease-in-out forwards;
			}

			.letter-e {
				animation: ${writeSvg} 1s 0.6s ease-in-out forwards;
			}

			.letter-a {
				animation: ${writeSvg} 1s 0.85s ease-in-out forwards;
			}

			.letter-t {
				animation: ${writeSvg} 1s 1.1s ease-in-out forwards;
			}

			.letter-t-cross {
				animation: ${writeSvg} 1s 1.35s ease-in-out forwards;
			}

			.comma {
				animation: ${writeSvg} 0.75s 1.75s ease-in-out forwards;
			}
		`}

	@media(max-width: 768px) {
		width: 100%;
		height: 100%;
		left: -10px;
	}
`;
const MeetDashWrapper = styled.div<{ isOnScreen: boolean }>`
	width: 213px;
	height: 18px;
	position: absolute;
	top: -20%;
	left: -10%;

	.meet-dash {
		stroke-dasharray: 500;
		stroke-dashoffset: 500;
	}

	${({ isOnScreen }) =>
		isOnScreen &&
		css`
			.meet-dash {
				animation: ${writeSvg} 0.5s ease-in-out forwards;
			}
		`}

	@media(max-width: 768px) {
		width: 110%;
		height: 100%;
		left: -10px;
	}
`;
const Content = styled.div`
	background: url(https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Steak-Story/steak-meet/meet-bottom.webp)
		center no-repeat;
	background-size: cover;
	min-height: 1500px;
	overflow-x: hidden;
`;
const List = styled.ul`
	max-width: 1100px;
	margin: 0 auto;
	list-style: none;
	padding: 60px 0 150px;
	position: relative;

	@media (max-width: 768px) {
		padding: 0;
	}
`;

export const SteakStoryMeet = forwardRef<HTMLDivElement>((_, ref) => {
	const meetRef = useRef<HTMLHeadingElement>(null);
	const meetIsOnScreen = useIsOnScreen(meetRef, { rootMargin: '-50px 0px' });

	const [modalItemIndex, setModalItemIndex] = useState<number | null>(null);

	const onModalChange = (direction: 'prev' | 'next') => {
		if (modalItemIndex === null) return;

		const first = 0;
		const last = items.length - 1;

		let newIndex = 0;

		if (direction === 'prev') {
			if (modalItemIndex === first) newIndex = last;
			else newIndex = modalItemIndex - 1;
		} else if (direction === 'next') {
			if (modalItemIndex === last) newIndex = first;
			else newIndex = modalItemIndex + 1;
		}

		setModalItemIndex(newIndex);
	};

	return (
		<>
			<Container ref={ref}>
				<Top>
					<h2 ref={meetRef}>
						<span>
							Meet{' '}
							<MeetWrapper isOnScreen={meetIsOnScreen}>
								<Meet />
							</MeetWrapper>
							<MeetDashWrapper isOnScreen={meetIsOnScreen}>
								<MeetDash />
							</MeetDashWrapper>
						</span>{' '}
						the family
					</h2>
					<p>
						At Outback Steakhouse, we offer our 7 juicy, tender cuts of steak for you to choose from.
						Explore our selection of expertly prepared steaks. Each one is unforgettable.
					</p>
				</Top>
				<Content>
					<List>
						{items.map((item, i) => (
							<SteakStoryMeetItem key={i} {...item} onClick={() => setModalItemIndex(i)} />
						))}
					</List>
				</Content>
			</Container>

			{modalItemIndex !== null && (
				<SteakStoryModal
					item={items[modalItemIndex]}
					onPrev={() => onModalChange('prev')}
					onNext={() => onModalChange('next')}
					onClose={() => setModalItemIndex(null)}
				/>
			)}
		</>
	);
});

SteakStoryMeet.displayName = 'SteakStoryMeet';
