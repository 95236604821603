import { ButtonCMS, ButtonCMSProps, ButtonCMSVariant } from '@maverick/cms';
import { LinkField, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

type ButtonCMSRenderingProps = ComponentProps & {
	fields: {
		Button: LinkField;
		CustomFontSize: { value: string };
	};
	params: {
		Variant: string;
	};
};

const ButtonCmsRendering = (props: ButtonCMSRenderingProps): JSX.Element => {
	const handleVariant = (): ButtonCMSVariant => {
		const variant = props?.rendering?.params?.Variant?.toLocaleLowerCase() as ButtonCMSVariant;
		return variant ?? 'primary';
	};

	const ButtonCMSProps: ButtonCMSProps = {
		variant: handleVariant(),
		button: {
			href: props?.fields?.Button?.value?.href ?? '',
			label: props?.fields?.Button?.value?.text ?? '',
			target: props?.fields?.Button?.value?.target ?? '_blank',
		},
		customFontSize: props?.fields?.CustomFontSize?.value,
	};

	return <ButtonCMS {...ButtonCMSProps} />;
};

export default withDatasourceCheck()<ButtonCMSRenderingProps>(ButtonCmsRendering);
