import { FC } from 'react';
import { NutritionWrapper } from './NutritionWrapper';
import styled, { css } from 'styled-components';

const Container = styled.div`
	position: relative;
	max-width: 30vw;
	margin: 0 auto;

	@media (max-width: 768px) {
		max-width: 100%;
		padding: 160px 0;
	}
`;
const Logo = styled.img`
	max-width: 187px;
	display: block;
	margin: 32px auto;
`;
const Img = styled.img<{ position: 'left' | 'right' }>`
	position: absolute;
	max-width: 350px;
	border: 8px solid #fff;
	box-shadow: 0 20px 155px -54px #aaa;

	${({ position }) => {
		if (position === 'left') {
			return css`
				top: 0;
				left: -380px;
				rotate: -5deg;
			`;
		} else {
			return css`
				bottom: 60px;
				right: -380px;
				rotate: 5deg;
			`;
		}
	}}

	@media(max-width: 768px) {
		max-width: unset;
		height: 150px;
		left: 50%;
		transform: translateX(-50%);

		${({ position }) => {
			if (position === 'left') {
				return css`
					top: -12px;
				`;
			} else {
				return css`
					bottom: -12px;
				`;
			}
		}}
	}
`;

const Title = styled.div`
	font-family: BigBloke;
	font-size: 44px;
	font-weight: 400;
	color: #891a1c;
	text-transform: uppercase;
	text-align: center;

	@media (max-width: 768px) {
		font-size: 32px;
	}
`;

const Subtitle = styled.div`
	font-family: Roboto, sans-serif;
	font-size: 18px;
	font-weight: 400;
	color: #2c2c2b;
	text-align: center;
	margin: 24px 0 10px 0;

	@media (max-width: 768px) {
		font-size: 16px;
	}
`;

export const KidsLiveWell: FC = () => {
	return (
		<NutritionWrapper activeLink={2}>
			<Container>
				<Title>Kids Live Well</Title>
				<Subtitle>
					We like to see our Joeys happy and healthy. Outback Steakhouse offers a variety of fun and flavorful
					kid's menu options to make any kid (and parent) happy. And when it comes to eating well, we can
					help. Outback's kids menu has a variety of fresh fruits, vegetables and grilled lean proteins like
					Joey Grilled Chicken on the Barbie alongside kid favorites like our Macaroo n' Cheese.
					<br />
					<br />
					Outback is a proud member of the National Restaurant Association's Kids LiveWell program. Our
					signature Joey Sirloin and Joey Grilled Chicken on the Barbie paired with plain steamed seasonal
					veggie or fresh fruit and a choice of 100% apple or orange juice meet the Kids LiveWell's
					nutritional criteria.
					<Logo src='https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Nutrition/kids-live-well/kids-live-well-logo-2.webp' />
					Click here to learn more about Kids LiveWell:
					<br />
					<a href='https://restaurant.org/KidsLiveWell' target='_blank'>
						Restaurant.org/KidsLiveWell
					</a>
				</Subtitle>

				<Img
					src='https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Nutrition/kids-live-well/kids-live-well-1.webp'
					alt='vegetables image'
					position='left'
				/>
				<Img
					src='https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Nutrition/kids-live-well/kids-live-well-2.webp'
					alt='fresh fruits and grilled lean proteins image'
					position='right'
				/>
			</Container>
		</NutritionWrapper>
	);
};
