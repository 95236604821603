export interface Teammate {
	name: string;
	info: string;
	text: string;
	quote: string;
	handler: {
		label: string;
		link: string;
	};
	photo: string;
}

export const Teammates: Readonly<Array<Teammate>> = [
	{
		name: 'Braelon Allen #0',
		info: 'Running Back | Madison, WI',
		text: 'Braelon is from Fond du Lac, WI and is currently a sophomore majoring in Communications. Off the field, he likes lifting weights and playing video games. He enjoys listening to Lil Baby to get ready before each game. Fun fact: He skipped senior year of high school and was a 17-year-old freshman at Wisconsin last year.',
		quote: '"My favorite Outback meal is Grilled Shrimp on the Barbie, with a New York Strip and Aussie Fries."',
		handler: {
			label: '@brae1on',
			link: 'https://www.instagram.com/brae1on/',
		},
		photo: 'teammates-braelon.webp',
	},
	{
		name: 'Kendall Milton #2',
		info: 'Running Back | Athens, GA',
		text: 'Kendall is from Fresno, CA and is currently a junior majoring in Consumer Economics. Off the field, he likes playing video games. He enjoys listening to Hip Hop to get ready before each game. Fun fact: He has a love for super cars.',
		quote: '"My favorite Outback meal is Aussie Cheese Fries, with a Bone-In Ribeye and Steakhouse Mac & Cheese."',
		handler: {
			label: '@therealkmilt',
			link: 'https://www.instagram.com/therealkmilt/',
		},
		photo: 'teammates-kendall.webp',
	},
	{
		name: 'Jon Seaton #90',
		info: 'Defensive Lineman | Elon, NC',
		text: 'Jon is from Hillsborough, NJ and is currently a junior majoring in Strategic Communications. Off the field, he likes watching reruns of old comedy shows. He enjoys listening to Mike Dimes to get ready before each game. Fun fact: He has an uncanny love for cheesecake.',
		quote: '"My favorite Outback meal is a Bloomin\' Onion, with a Bone-In Ribeye and Steakhouse Mac & Cheese."',
		handler: {
			label: '@Sea76n',
			link: 'https://www.instagram.com/Sea76n/',
		},
		photo: 'teammates-jon.webp',
	},
	{
		name: 'A.J. Henning #3',
		info: 'Wide Receiver/Punt Returner | Ann Arbor, MI',
		text: 'A.J. is from Frankfort, IL and is currently a junior majoring in Communications. Off the field, he likes playing video games. He enjoys listening to Lil Uzi to get ready before each game. Fun fact: He can play the piano.',
		quote: '"My favorite Outback meal is Kookaburra Wings, with a Filet Mignon and Steakhouse Mac & Cheese."',
		handler: {
			label: '@Ajh3nning',
			link: 'https://www.instagram.com/Ajh3nning/',
		},
		photo: 'teammates-aj.webp',
	},
];

export interface Alumnus {
	name: string;
	info: string;
	handler: {
		label: string;
		link: string;
	};
	photo: string;
}

export const Alumni: Readonly<Array<Alumnus>> = [
	{
		name: 'Jocelyn Alo #78',
		info: 'Outfield | Norman, OK',
		handler: {
			label: '@jocygurl78',
			link: 'https://www.instagram.com/jocygurl78/',
		},
		photo: 'alumni-jocelyn.webp',
	},
	{
		name: 'Jayda Coleman #24',
		info: 'Outfield | Norman, OK',
		handler: {
			label: '@jayda_coleman10',
			link: 'https://www.instagram.com/jayda_coleman10/',
		},
		photo: 'alumni-jayda.webp',
	},
	{
		name: 'Jordan Beck #27',
		info: 'Outfield | Knoxville, TN',
		handler: {
			label: '@jordan_beck27',
			link: 'https://www.instagram.com/jordan_beck27/',
		},
		photo: 'alumni-jordan.webp',
	},
	{
		name: 'Ben Joyce #44',
		info: 'Pitcher | Knoxville, TN',
		handler: {
			label: '@ben_joyce08',
			link: 'https://www.instagram.com/ben_joyce08/',
		},
		photo: 'alumni-ben.webp',
	},
	{
		name: 'Hunter Barco #12',
		info: 'Pitcher | Gainesville, FL',
		handler: {
			label: '@hunterbarco',
			link: 'https://www.instagram.com/hunterbarco/',
		},
		photo: 'alumni-hunter.webp',
	},
	{
		name: 'Colby Halter #5',
		info: '2B/3B | Gainesville, FL',
		handler: {
			label: '@colbyhalter',
			link: 'https://www.instagram.com/colbyhalter/',
		},
		photo: 'alumni-colby.webp',
	},
	{
		name: 'Baylee Klingler #8',
		info: 'Shortstop | Seattle, WA',
		handler: {
			label: '@bayleekling',
			link: 'https://www.instagram.com/bayleekling/',
		},
		photo: 'alumni-baylee.webp',
	},
	{
		name: 'Kelley Lynch #27',
		info: 'Pitcher/1B | Seattle, WA',
		handler: {
			label: '@kelley_lynch',
			link: 'https://www.instagram.com/kelley_lynch/',
		},
		photo: 'alumni-kelley.webp',
	},
];
