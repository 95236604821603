import { RichText, Field, Image, withDatasourceCheck, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';
import { Gallery, GalleryProps, TwoImagesGallery, GalleryVariant } from '@maverick/ui';
import { ComponentProps } from 'lib/component-props';

type GalleryRenderingProps = ComponentProps & {
	rendering: {
		fields: {
			Title: Field<string>;
			Text: Field<string>;
			Subtitle: Field<string>;
			Image1: ImageField;
			Image2?: { value?: { src?: string } };
			Button: { value: { href: string; text: string; target: string } };
			Instagram: { value: { href: string; text: string; target: string } };
			Linkedin: { value: { href: string; text: string; target: string } };
			Facebook: { value: { href: string; text: string; target: string } };
		};
		params: {
			Variant: string;
		};
	};
};

const GalleryRendering = (props: GalleryRenderingProps): JSX.Element => {
	const { Title, Subtitle, Text, Image1, Image2, Button, Instagram, Linkedin, Facebook } = props?.rendering?.fields;
	const variant = props?.rendering?.params?.Variant as GalleryVariant;
	const galleryProps: GalleryProps = {
		variant: variant,
		title: <RichText field={Title} />,
		text: <RichText field={Text} />,
		subtitle: Subtitle.value,
		image: <Image field={Image1} />,
		button: {
			label: Button.value.text,
			href: Button.value.href,
			openInANewTab: (Button?.value?.target ?? '') === '_blank',
		},
		socialMedia: [
			{
				label: Instagram?.value?.text,
				href: Instagram?.value?.href,
				img: '/image/icon-instagram.png',
				openInANewTab: (Instagram?.value?.target ?? '') === '_blank',
			},
			{
				label: Linkedin?.value?.text,
				href: Linkedin?.value?.href,
				img: '/image/icon-linkedin.png',
				openInANewTab: (Linkedin?.value?.target ?? '') === '_blank',
			},
			{
				label: Facebook?.value?.text,
				href: Facebook?.value?.href,
				img: '/image/icon-facebook.png',
				openInANewTab: (Facebook?.value?.target ?? '') === '_blank',
			},
		],
	};

	if (variant === GalleryVariant.Terciary) {
		const twoImagesGalleryProps = {
			...galleryProps,
			imageTwo: <Image field={Image2} />,
		};
		return <TwoImagesGallery {...twoImagesGalleryProps} />;
	}

	return <Gallery {...galleryProps} />;
};

export default withDatasourceCheck()<GalleryRenderingProps>(GalleryRendering);
